import { useState } from "react";

import { MessageSuggestionReport } from "./MessageSuggestionReport";
import styles from "./MessageSuggestionReportModal.module.scss";

import Button from "../button/Button";
import Modal from "../modal/Modal";

import { ReportMessageProps } from "~/hooks/useApi/useCoachSuggestedMessages";
import { t } from "~/i18n";
import { MessageSuggestionDto } from "~/typing/graphql/types";

type MessageSuggestionReportModalProps = {
  message: MessageSuggestionDto;
  userId: string;
  setShowReportModal: (showReportModal: boolean) => void;
  handleMessageReported: (
    reportMessage: ReportMessageProps,
    index: number
  ) => void;
  reportIsPending: boolean;
  currentMessageIndex: number;
};

export const MessageSuggestionReportModal = ({
  setShowReportModal,
  handleMessageReported,
  reportIsPending: isPending,
  currentMessageIndex,
  ...rest
}: MessageSuggestionReportModalProps) => {
  const [reportMessage, setReportMessage] = useState<ReportMessageProps>();
  const handleReportMessage = () => {
    if (!reportMessage) return;
    handleMessageReported(reportMessage, currentMessageIndex);
    setShowReportModal(false);
  };

  const canReport = reportMessage?.issues.length || reportMessage?.note;

  return (
    <Modal
      title={t("nextStep.reportMessage.title")}
      onClose={() => setShowReportModal(false)}
      className={styles.modal}
    >
      <MessageSuggestionReport
        {...rest}
        onReportMessageChange={(message) => setReportMessage(message)}
      />
      <div className={styles.modalButtons}>
        <Button
          className={styles.modalButton}
          onClick={() => setShowReportModal(false)}
          inverted
        >
          {t("general.cancel")}
        </Button>
        <Button
          className={`${styles.modalButton} `}
          onClick={() => handleReportMessage()}
          isLoading={isPending}
          disabled={!canReport}
        >
          {t("nextStep.report")}
        </Button>
      </div>
    </Modal>
  );
};
