import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import config from "~/config";
import { Survey } from "~/typing/sidekickTypes";

interface Surveys {
  items: Survey[];
}

export default function useSurveys({
  programCatalogItemId,
  locale,
  includeQuizzes = true
}: {
  programCatalogItemId: string;
  locale: string;
  includeQuizzes?: boolean;
}) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/surveys${
    config.isAnthem ? `?displayLocale=en` : ""
  }`;
  const { data, isError, isLoading, invalidate } = useApi<Surveys>({
    path,
    queryKey: QueryKeyFactory.programs.surveys(programCatalogItemId, locale)
  });
  return {
    surveys: includeQuizzes
      ? data?.items || []
      : data?.items.filter((survey) => !survey.options.quiz) || [],
    isError,
    isLoading,
    invalidate
  };
}
