import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";


import styles from "./SMSModal.module.scss";

import Button from "~/components/button/Button";
import Modal from "~/components/modal/Modal";
import { shouldNotMentionElevance } from "~/helpers/user/userHelpers";
import { useSendInviteSms } from "~/hooks/useApi/messages/useSendSmsMessage";
import useExternalUserGroups from "~/hooks/useApi/useUserGroups";
import { ProgramURLParams } from "~/typing/carePortalTypes";
import { ExternalUser } from "~/typing/sidekickTypes";

type SMSModalProps = {
  onClose: () => void;
  externalUser: ExternalUser;
  smsTemplate?: string;
};

export default function SMSModal({
  onClose,
  externalUser,
  smsTemplate
}: SMSModalProps) {
  const { t } = useTranslation();
  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  // only append to the sms if there is no pinCodeUrl or landinPageurl defined
  const smsTemplateFull = smsTemplate
    ? `${smsTemplate} ${
        !smsTemplate.includes("{pinCodeUrl}") &&
        !smsTemplate.includes("{landingPageUrl}") &&
        externalUser?.pinCodeUrl
          ? externalUser.pinCodeUrl
          : ""
      }`
    : "";
  const [smsText, setSmsText] = useState(smsTemplateFull);
  const { groups } = useExternalUserGroups();
  const {
    sendInviteSmsMessage,
    isPending: smsInviteIsPending,
    isSuccess
  } = useSendInviteSms({
    locale,
    programId: program_id,
    externalUser
  });

  const handleSubmitSMSInvite = async (e) => {
    e.preventDefault();
    sendInviteSmsMessage({ message: smsText });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal title="New SMS" onClose={onClose} className={styles.modal}>
      {shouldNotMentionElevance(groups, externalUser) && (
        <span className={styles.ibcClient}>{t("forbiddenMentions.noMentioningElevance")}</span>
      )}
      <div className={styles.phoneNumberContainer}>
        <span>To: </span>
        {externalUser?.phoneNumber}
      </div>
      <form onSubmit={handleSubmitSMSInvite} className={styles.form}>
        <div className={styles.inputContainer}>
          <textarea
            className={"input"}
            value={smsText}
            onChange={(e) => setSmsText(e.target.value)}
            placeholder={t("registerPatient.smsPlaceholder")}
            rows={10}
            autoFocus
          />
        </div>
        <div className={styles.actions}>
          <Button
            type="submit"
            className={"btn-primary btn-sm"}
            disabled={
              !smsText || smsInviteIsPending || !externalUser?.phoneNumber
            }
            isLoading={smsInviteIsPending}
          >
            {t("general.send")}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
