import classNames from "classnames";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import styles from "./CareManagerTaskList.module.scss";
import { CareManagerTaskListItem } from "./CareManagerTaskListItem";

import AlertCircle from "~/assets/alert-circle.svg";
import CheckCircleBig from "~/assets/check-circle-big.svg";
import ChevronLeft from "~/assets/chevron-left-next-step.svg";
import ChevronRight from "~/assets/chevron-right-next-step.svg";
import SearchInput from "~/components/searchInput/SearchInput";
import CustomSelect from "~/components/select/CustomSelect";
import SkeletonCareManagerTasks from "~/components/skeletons/SkeletonCareManagerTasks";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { useGetCareManagerTask } from "~/hooks/graphql/useCareManagerTasks";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { SelectOption } from "~/typing/carePortalTypes";
import { CareManagerTaskDto, CarePriorityType } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

const NUMBER_OF_ROWS_OPTIONS = [
  { value: 5, text: 5 },
  { value: 10, text: 10 }
];

export const CareManagerTaskList = () => {
  const {
    careManagerTasks,
    isError,
    isLoading,
    status
  } = useGetCareManagerTask();

  const [careManagerTasksToDisplay, setCareManagerTasksToDisplay] = useState<
    CareManagerTaskDto[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [chosenProgramName, setChosenProgramName] = useState(
    DEFAULT_FILTER_VALUE
  );
  const [chosenCarePriority, setChosenCarePriority] = useState(
    DEFAULT_FILTER_VALUE
  );
  const [programOptions, setProgramOptions] = useState<SelectOption[]>([]);
  const [taskOptions, setTaskOptions] = useState<SelectOption[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalRecords / pageSize)
  );

  const [showOptions, setShowOptions] = useState(false);

  const {
    trackTaskPaginatorClicked,
    trackTaskRowsChanged
  } = useAmplitudeTracking();

  const filterTasks = useCallback(
    (careManagerTasks: CareManagerTaskDto[]) => {
      return careManagerTasks?.filter((task) => {
        const matchesSearch = searchValue
          ? task?.userFullName
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
          : true;

        const matchesProgram =
          chosenProgramName && chosenProgramName !== DEFAULT_FILTER_VALUE
            ? task?.programCatalogItemName === chosenProgramName
            : true;

        const matchesPriority =
          chosenCarePriority && chosenCarePriority !== DEFAULT_FILTER_VALUE
            ? task?.carePriorities.includes(
                chosenCarePriority as CarePriorityType
              )
            : true;

        return matchesSearch && matchesProgram && matchesPriority;
      });
    },
    [searchValue, chosenProgramName, chosenCarePriority]
  );

  useEffect(() => {
    if (!careManagerTasks || status !== "success" || programOptions.length)
      return;

    const tempProgramOptions: Set<string> = new Set();
    const tempTaskOptions: Set<string> = new Set();

    careManagerTasks?.careManagerTaskDtos?.forEach((task) => {
      if (task?.programCatalogItemName) {
        tempProgramOptions.add(task.programCatalogItemName);
      }
      task?.carePriorities.forEach((carePriority) => {
        tempTaskOptions.add(carePriority ?? "");
      });
    });

    const options = Array.from(tempProgramOptions).map((program) => ({
      text: program,
      value: program
    }));

    const taskOptions = Array.from(tempTaskOptions).map((task) => ({
      text: convertEnumToTitleCase(task),
      value: task
    }));

    setProgramOptions(
      [{ text: "All programs", value: DEFAULT_FILTER_VALUE }].concat(options)
    );

    setTaskOptions(
      [{ text: "All tasks", value: DEFAULT_FILTER_VALUE }].concat(taskOptions)
    );
  }, [careManagerTasks]);

  useEffect(() => {
    if (careManagerTasks && status === "success") {
      if (
        careManagerTasks.careManagerTaskDtos &&
        careManagerTasks.careManagerTaskDtos.length > 0
      ) {
        const filteredCareManagerTasks = filterTasks(
          (careManagerTasks?.careManagerTaskDtos ?? []) as CareManagerTaskDto[]
        );

        const careManagerTasksToDisplay = filteredCareManagerTasks.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        ) as CareManagerTaskDto[];

        setCareManagerTasksToDisplay(careManagerTasksToDisplay);
        setTotalRecords(filteredCareManagerTasks?.length as number);
        setShowOptions(true);
      }
    }
  }, [
    careManagerTasks,
    currentPage,
    pageSize,
    searchValue,
    chosenProgramName,
    chosenCarePriority
  ]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageSize));
  }, [totalRecords, pageSize]);

  const handleSetPageSize = (e: any) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    trackTaskRowsChanged();
  };

  const handlePageChange = (dir: "prev" | "next") => {
    if (dir === "prev") {
      setCurrentPage(currentPage - 1);
      trackTaskPaginatorClicked({ direction: "previous" });
    } else {
      setCurrentPage(currentPage + 1);
      trackTaskPaginatorClicked({ direction: "next" });
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  const handleProgramChange = (e: any) => {
    setChosenProgramName(e.target.value ?? "N/A");
    setCurrentPage(1);
  };

  const handleCarePriorityChange = (e: any) => {
    setChosenCarePriority(e.target.value ?? "N/A");
    setCurrentPage(1);
  };

  return (
    <>
      <div className={styles.header}>
        <h2>{t("nextStep.careManagerTasks.title")} </h2>
        {!isLoading && (
          <div className={styles.filters}>
            <SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <CustomSelect
              options={taskOptions}
              renderOption={(option) => option.text}
              value={chosenCarePriority}
              onChange={handleCarePriorityChange}
            />
            <CustomSelect
              options={programOptions}
              renderOption={(option) => option.text}
              value={chosenProgramName}
              onChange={handleProgramChange}
            />
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {!isLoading &&
          careManagerTasksToDisplay?.map((task: CareManagerTaskDto, index) => (
            <CareManagerTaskListItem task={task} key={`key-cmtd-${index}`} />
          ))}
        {!isLoading && careManagerTasksToDisplay?.length === 0 && (
          <div className={styles.emptyContainer}>
            <div
              className={cx({
                [styles.empty]: !isError,
                [styles.error]: isError
              })}
            >
              <img src={isError ? AlertCircle : CheckCircleBig} alt="Check" />
              {!isError
                ? t("nextStep.careManagerTasks.noTasks")
                : t("nextStep.careManagerTasks.error")}
            </div>
          </div>
        )}
      </div>

      {showOptions && (
        <div className={styles.footer}>
          <div className={styles.settings}>
            {t("nextStep.careManagerTasks.rowsPerPage")}
            <CustomSelect
              options={NUMBER_OF_ROWS_OPTIONS}
              renderOption={(option) => option.text}
              value={pageSize}
              onChange={(e) => handleSetPageSize(e)}
              className={styles.select}
            />
            <div className={styles.pages}>
              {pageSize * (currentPage - 1) + 1} -{" "}
              {Math.min(pageSize * currentPage, totalRecords)}{" "}
              {t("nextStep.careManagerTasks.of")} {totalRecords}
            </div>
            <div className={styles.prevNext}>
              <button
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
              >
                <img src={ChevronLeft} />
              </button>
              <button
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
              >
                <img src={ChevronRight} />
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <SkeletonCareManagerTasks />}
    </>
  );
};
