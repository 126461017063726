import classNames from "classnames";
import { Trans } from "react-i18next";

import { BehavioralPROStatusType } from "./BehavioralPRO.constants";
import styles from "./BehavioralPRO.tooltips.module.scss";

import { t } from "~/i18n";
import { BehavioralPROAnswerData } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);

export const PROStatusTooltip = (status: BehavioralPROStatusType) => {
  let tooltipText = "";

  if (status === BehavioralPROStatusType.Struggling) {
    tooltipText = t("nextStep.behavioralPro.struggling");
  } else if (status === BehavioralPROStatusType.Managing) {
    tooltipText = t("nextStep.behavioralPro.progressing");
  } else {
    tooltipText = t("nextStep.behavioralPro.thriving");
  }

  return <Trans components={{ strong: <strong /> }}>{tooltipText}</Trans>;
};

export const PROBehavioralAnswerTooltip = ({
  data,
  answer
}: {
  data: BehavioralPROAnswerData;
  answer: number;
}) => {
  return (
    <div className={styles.proAnswerTooltip}>
      <p className={styles.title}>
        <strong>{data.title}</strong> - {data.description}
      </p>
      <div className={styles.options}>
        {data.values.map((value, index) => (
          <div key={index} className={styles.option}>
            <div
              className={cx(styles.value, {
                [styles.selected]: index + 1 === answer
              })}
            >
              {index + 1}
            </div>
            <p>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const BehavioralPROInformationTooltip = () => {
  return (
    <div className={styles.infoTooltip}>
      <p>{t("nextStep.behavioralPro.information.surveys")}</p>
      <p>{t("nextStep.behavioralPro.information.range")}</p>
    </div>
  );
};
