import { useQuery } from "@tanstack/react-query";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";

import { getFallbackMessage } from "~/api/graphql/nextStep";
import { QueryGetFallbackMessageArgs } from "~/typing/graphql/types";

export const useFallbackMessage = (props: QueryGetFallbackMessageArgs) => {
  const { data, isLoading, isError, refetch, status, isSuccess } = useQuery({
    queryKey: QueryKeyFactory.users.fallbackMessage(props.locale, props.userId),
    queryFn: () => getFallbackMessage(props),
    staleTime: 1000 * 60 * 10 // 10 minutes This can be adjusted based on the requirement and testing,
  });

  return {
    fallbackMessage: data,
    isLoading,
    isError,
    refetch,
    isSuccess,
    status
  };
};
