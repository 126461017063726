import { useEffect, useState } from "react";

import styles from "./ImportExternalUsersPage.module.scss";

import api from "~/api/api";
import { getImportableExternalUserType } from "~/api/requests/externalUserRequests";
import { Exception } from "~/classes/exception/Exception";
import NarrowLayout from "~/components/layout/NarrowLayout";
import CustomSelect from "~/components/select/CustomSelect";
import { FileUploadType } from "~/constants/fileUploadTypes";
import { ExternalUserType } from "~/typing/sidekickTypes";

type ImportExternalUsersPageProps = {
  getExport?: (props: {
    setImportFinished: (value: boolean) => void;
    uploadToken: string;
    externalUserTypeName: string;
    setResponseMessage: (value: string) => void;
  }) => Promise<void>;
  fileUploadType: FileUploadType;
};

type Payload = {
  file: string;
  uploadToken: string;
};

const ImportExternalUsersPage = ({
  getExport,
  fileUploadType
}: ImportExternalUsersPageProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadToken, setUploadToken] = useState("");
  const [uploadName, setUploadName] = useState<string | undefined>(undefined);
  const [externalUserTypeName, setExternalUserTypeName] = useState<string>();
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [payload, setPayload] = useState<Payload>();
  const [responseMessages, setResponseMessages] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [importFinished, setImportFinished] = useState(false);
  const [action, setAction] = useState("Importing");
  const [externalUserTypes, setExternalUserTypes] = useState<
    ExternalUserType[]
  >([]);
  let pollCount = 0;
  let polling = false;

  const determineRowCount = (csv) => {
    try {
      const splitData = csv.split(/\r?\n/);
      setRowCount(splitData ? splitData.length : 0);
    } catch (err: any) {
      setResponseMessage(err);
    }
  };

  useEffect(() => {
    const getExternalUserTypes = async () => {
      const response = await getImportableExternalUserType();
      setExternalUserTypes(response);
    };
    getExternalUserTypes();
  }, []);

  const onFileLoad = (event) => {
    const csv = event.target.result;
    const data = {
      file: btoa(csv),
      uploadToken: uploadToken
    };
    setPayload(data);
    determineRowCount(csv);
  };

  const handleUpload = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = onFileLoad;
    fileReader.readAsText(file);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    handleUpload(event.target.files[0]);
  };

  const splitMessages = (message) => {
    try {
      setResponseMessages(message.split(","));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Are you sure you wish to import the selected users to ${externalUserTypeName}`
      )
    ) {
      setLoading(true);
      const uploadData = {
        ...payload,
        externalUserTypeName,
        fileUploadType,
        uploadName
      };

      await api
        .post("/admin/extras/uploads", uploadData)
        .then((res) => {
          if (res?.data?.id) {
            setResponseMessage("");
            splitMessages(res?.data?.details[0]);
            polling = true;
            setUploaded(true);
            pollUploadStatus(res.data.id, true);
          }
        })
        .catch((err: Exception) => {
          setResponseMessage(
            `Error importing users, status:${err?.statusCode}`
          );
          setLoading(false);
        });
    }
  };

  const pollUploadStatus = (uploadId: string, firstTime?: boolean) => {
    if (polling || firstTime) {
      setTimeout(() => getFileUploadStatus(uploadId), firstTime ? 1000 : 5000);
    }
  };

  const getFileUploadStatus = async (uploadId: string) => {
    if (pollCount > 99) {
      setLoading(false);
      polling = false;
      setResponseMessage("Timeout exceeded.");
    } else {
      pollCount = pollCount + 1;
      const uploadStatus = await api.get(
        `/admin/extras/uploads/${uploadId}/status?token=${uploadToken}`
      );
      if (uploadStatus && uploadStatus.data) {
        setResponseMessage(uploadStatus.data?.details[0]);
        if (uploadStatus.data.status === 3) {
          polling = false;
          setLoading(false);
          setResponseMessages([]);
          if (getExport) {
            getExport({
              setImportFinished,
              uploadToken,
              externalUserTypeName: externalUserTypeName ?? "",
              setResponseMessage
            });
          }
        } else if (uploadStatus.data.status === 100) {
          // error
          setLoading(false);
          polling = false;
        } else {
          // poll again
          pollUploadStatus(uploadId);
        }
      }
    }
  };

  const handleExportClick = () => {
    if (getExport) {
      setAction("Exporting");

      getExport({
        setImportFinished,
        uploadToken,
        externalUserTypeName: externalUserTypeName ?? "",
        setResponseMessage
      });
    }
  };

  return (
    <NarrowLayout>
      <div className={styles.container}>
        {!importFinished && (
          <form onSubmit={handleSubmit}>
            <h1>Import/Export external users</h1>
            {!loading && (
              <div>
                <label>Upload token</label>
                <input
                  type="text"
                  className="input"
                  value={uploadToken}
                  onChange={(e) => setUploadToken(e.target.value)}
                />
                <label>Name</label>
                <input
                  type="text"
                  className="input"
                  value={uploadName}
                  onChange={(e) => setUploadName(e.target.value)}
                />
                <CustomSelect
                  label="Program"
                  value={externalUserTypeName}
                  placeholder="Select program"
                  valueKey="name"
                  onChange={(e) => setExternalUserTypeName(e.target.value)}
                  renderOption={(option) =>
                    `${option?.title} - ${option?.name}`
                  }
                  options={externalUserTypes}
                />
              </div>
            )}
            {loading && <div>loading...</div>}
            {selectedFile && !uploaded && (
              <div className={styles.fileInfo}>
                {selectedFile.name}, number of rows: {rowCount}
              </div>
            )}
            <div className={styles.fileInfo}>{responseMessage}</div>
            <div>
              {responseMessages &&
                responseMessages.map((message, key) => {
                  return (
                    <div key={key} className={styles.fileInfo}>
                      {message}
                    </div>
                  );
                })}
            </div>
            {!uploaded && !polling && !loading && (
              <div>
                <label>
                  Click browse to upload your csv file, alternatively you can
                  export users using the export button
                </label>
                <div className={styles.btnWrapper}>
                  <div className={styles.fileUploadWrapper}>
                    <input
                      disabled={loading}
                      name="file-upload-field"
                      type="file"
                      accept=".csv"
                      onChange={changeHandler}
                      className={`btn btn-primary ${styles.fileUploadField}`}
                    />
                  </div>
                  {!uploaded &&
                    selectedFile &&
                    rowCount > 1 &&
                    !polling &&
                    !loading && (
                      <button
                        disabled={
                          loading ||
                          !uploadToken ||
                          !externalUserTypeName ||
                          !payload
                        }
                        className={`${styles.customFileInput} btn-secondary`}
                        type="submit"
                      >
                        Confirm upload
                      </button>
                    )}
                  {!selectedFile && getExport && (
                    <button
                      className={`btn-sm btn-secondary ${styles.exportBtn}`}
                      disabled={
                        loading || !uploadToken || !externalUserTypeName
                      }
                      onClick={() => handleExportClick()}
                    >
                      Export
                    </button>
                  )}
                </div>
              </div>
            )}
          </form>
        )}
        {importFinished && (
          <>
            <div className={styles.fileInfo}>
              {responseMessage.split(",").map((str, index) => (
                <p key={`response-string-${index}`}>{str}</p>
              ))}
            </div>
            {(!responseMessage || !responseMessage.includes("error")) && (
              <div className={styles.fileInfo}>
                {action} finished, csv file should automatically start to
                download
              </div>
            )}
          </>
        )}
      </div>
    </NarrowLayout>
  );
};

export default ImportExternalUsersPage;
