import { useState, useEffect } from "react";

import MessageList from "./MessageList";
import {
  coachHasUnreadMessage,
  getCoachOptions,
  getCoachOverallNotifiactionStyle,
  getCoachTitle,
  idBelongsToUser
} from "./MessageWindow.helpers";
import styles from "./MessageWindow.module.scss";
import { MessageWindowContext } from "./messageWindowContext";
import MessageWindowFooter from "./MessageWindowFooter";

import ChevronDown from "~/assets/chevron-down.svg";
import CloseIcon from "~/assets/close.svg";
import SubtractIcon from "~/assets/subtract.svg";
import CoachRecommendedMessageModal from "~/components/messageModal/CoachRecommendedMessageModal";
import Notification from "~/components/notification/Notification";
import Options from "~/components/options/Options";
import config from "~/config";
import { TimeInMs } from "~/constants/measurements";
import { isMedicareProgram } from "~/helpers/program/programHelpers";
import { shouldNotMentionElevance } from "~/helpers/user/userHelpers";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import useExternalUserGroups from "~/hooks/useApi/useExternalUserGroups";
import useInterval from "~/hooks/useInterval";
import { t } from "~/i18n";
import { UnreadMessagesState } from "~/pages/user/userPage/unreadMessagesReducer";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { SMSSettings } from "~/typing/carePortalTypes";
import {
  CareTeamMember,
  Coach,
  EnrichedAuthenticatedUser,
  MessageType,
  Program,
  Specialty,
  UserDetailsUser
} from "~/typing/sidekickTypes";

type MessageWindowProps = {
  patient?: {
    user: UserDetailsUser;
    externalUser?: { externalUserTypeId: string; id: string };
  };
  onClose: () => void;
  onSubmitMessage: (props: {
    message: string;
    replyingToMessage?: MessageType;
    messageSuggestionId?: string;
  }) => void | Promise<void>;
  showMessages: boolean;
  usersForAvatars?: { [key: number]: Coach };
  smsSettings: SMSSettings;
  authUser?: EnrichedAuthenticatedUser;
  messages: MessageType[];
  unreadMessages?: UnreadMessagesState;
  updateUnreadMessages?: () => void;
  specialties?: Specialty[];
  specialtyCoaches?: CareTeamMember[];
  programThatUserIsIn: Program;
};

const MessageWindow = ({
  patient,
  authUser,
  messages,
  onClose,
  onSubmitMessage,
  showMessages,
  usersForAvatars,
  smsSettings,
  unreadMessages,
  updateUnreadMessages,
  specialties,
  specialtyCoaches,
  programThatUserIsIn
}: MessageWindowProps) => {
  const [minimized, setMinimized] = useState(false);
  const [showAllCareManagers, setShowAllCareManagers] = useState(
    config.isAnthem
  );
  const [filteredMessages, setFilteredMessages] = useState<MessageType[]>([]);
  const [messageInInput, setMessageInInput] = useState("");
  const [showRecommendedReplies, setShowRecommendedReplies] = useState(false);
  const [coachOptions, setCoachOptions] = useState<any[]>([]);
  const [chosenManager, setChosenManager] = useState<Coach | undefined>(
    usersForAvatars?.[authUser?.id || authUser?.userId || ""]
  );
  const [shouldDisplayFooter, setShouldDisplayFooter] = useState(
    (smsSettings?.useSMS && !smsSettings?.userHasOptedOut) ||
      !smsSettings?.useSMS
  );
  const [messageBeingRepliedTo, setMessageBeingRepliedTo] = useState<
    MessageType | undefined
  >();
  const [notificationColor, setNotificationColor] = useState<
    string | undefined
  >(undefined);

  const { externalUser } = useExternalUser({
    externalUserId: patient?.externalUser?.id,
    externalUserTypeId: patient?.externalUser?.externalUserTypeId
  });
  const { groups } = useExternalUserGroups({
    disabled: !isMedicareProgram(programThatUserIsIn)
  });

  const {
    trackMessageChatMaximized,
    trackMessageChatMinimised,
    trackMessageChatClosed,
    trackMessageChatOpened,
    trackMessageThreadChanged,
    trackRecommendedMessagesViewed
  } = useAmplitudeTracking();

  const isAuthUser = (id: string) => idBelongsToUser(id, authUser);

  useEffect(() => {
    if (showMessages) {
      trackMessageChatOpened();
    }
  }, [showMessages]);

  useEffect(() => {
    if (smsSettings?.useSMS) {
      setShouldDisplayFooter(!smsSettings?.userHasOptedOut);
    } else if (showAllCareManagers && !messageBeingRepliedTo) {
      setShouldDisplayFooter(false);
    } else {
      setShouldDisplayFooter(
        isAuthUser(chosenManager?.userId ?? "") ||
          messageBeingRepliedTo !== undefined
      );
    }
  }, [chosenManager, messageBeingRepliedTo, showAllCareManagers, smsSettings]);

  useEffect(() => {
    setMessageBeingRepliedTo(undefined);
  }, [showAllCareManagers]);

  useEffect(() => {
    if (!unreadMessages) return;
    setNotificationColor(getCoachOverallNotifiactionStyle(unreadMessages));
  }, [unreadMessages]);

  useInterval(() => {
    if (updateUnreadMessages && showMessages) {
      updateUnreadMessages();
    }
  }, 5 * TimeInMs.Second);

  useEffect(() => {
    if (authUser && usersForAvatars && patient) {
      const onOptionClick = (userId?: string) => {
        if (userId) {
          setChosenManager(usersForAvatars[userId]);
          setShowAllCareManagers(false);
          trackMessageThreadChanged({
            fromUserId: chosenManager?.userId ?? "-1",
            from: chosenManager?.displayName ?? "all",
            toUserId: userId,
            to: usersForAvatars[userId].displayName
          });
        } else {
          setChosenManager(undefined);
          setShowAllCareManagers(true);
          trackMessageThreadChanged({
            fromUserId: chosenManager?.userId ?? "-1",
            from: chosenManager?.displayName ?? "all",
            toUserId: "-1",
            to: "all"
          });
        }
      };
      const options = getCoachOptions({
        messages,
        unreadMessages,
        usersForAvatars,
        patient: patient.user,
        loggedInUser: authUser,
        onOptionClick,
        specialtyCoaches,
        specialties
      });

      setCoachOptions(options);

      if (!config.isAnthem && !chosenManager && !showAllCareManagers) {
        setChosenManager(
          usersForAvatars?.[authUser?.id || authUser?.userId || ""]
        );
      }
    }
  }, [messages, authUser, usersForAvatars, patient]);

  useEffect(() => {
    if (!chosenManager?.userId || smsSettings?.useSMS || showAllCareManagers) {
      setFilteredMessages(messages);
      return;
    } else {
      setFilteredMessages(
        messages.filter((msg) => {
          return (
            msg.senderUserId === chosenManager?.userId ||
            msg.recipientUserId === chosenManager?.userId
          );
        })
      );
    }
  }, [showAllCareManagers, chosenManager?.userId, messages.length]);

  const handleSubmitMessage = async ({
    message,
    replyingToMessage,
    messageSuggestionId
  }: {
    message: string;
    replyingToMessage?: MessageType;
    messageSuggestionId?: string;
  }) => {
    await onSubmitMessage({
      message: message,
      replyingToMessage,
      messageSuggestionId
    });
    setMessageBeingRepliedTo(undefined);
  };

  const handleOnClose = () => {
    trackMessageChatClosed();
    onClose();
  };

  const handleMinimizeWindow = () => {
    if (minimized) {
      trackMessageChatMaximized();
    } else {
      trackMessageChatMinimised();
    }
    setMinimized(!minimized);
  };

  if (!patient) return null;

  return (
    <MessageWindowContext.Provider
      value={{
        usersForAvatars: usersForAvatars || {},
        authUser,
        minimized,
        chosenManager,
        allCareManagersShown: showAllCareManagers,
        setMessageBeingRepliedTo,
        unreadMessages,
        messageInInput,
        setMessageInInput,
        showRecommendedReplies,
        setShowRecommendedReplies,
        patient,
        onSubmitMessage: handleSubmitMessage
      }}
    >
      <div
        className={
          styles.MessageWindow + (showMessages ? "" : ` ${styles.hidden}`)
        }
      >
        <div className={styles.header}>
          <div
            data-testid="message-window-username-wrapper"
            className={styles.userNameWrapper}
          >
            <p className={styles.userName}>
              {patient.user.fullName?.split(" ")?.[0] ??
                `[${t("warnings.noName")}]`}
            </p>
            {!minimized && (
              <>
                {!smsSettings?.useSMS && (
                  <p
                    className={
                      isAuthUser(chosenManager?.userId ?? "") &&
                      !showAllCareManagers
                        ? styles.lowercase
                        : ""
                    }
                  >
                    {`${t("general.and")} ${
                      showAllCareManagers
                        ? t("messages.allCMs")
                        : isAuthUser(chosenManager?.userId ?? "")
                        ? t("general.you", "you")
                        : chosenManager?.displayName.split(" ")[0] ??
                          t("roles.careManager")
                    }`}
                  </p>
                )}
                {!smsSettings?.useSMS && (
                  <Options
                    dataTestId="message-window-options"
                    className={styles.options}
                    iconSrc={ChevronDown}
                    options={coachOptions}
                    mainOption={{
                      func: () => {
                        setShowAllCareManagers(false);
                        setChosenManager(
                          usersForAvatars?.[
                            authUser?.id || authUser?.userId || ""
                          ]
                        );
                      },
                      renderOption: function CoachOption() {
                        return (
                          <div className={styles.coachOption}>
                            <p>
                              <span className={styles.coachName}>
                                {t("general.you")}
                              </span>
                              <span>
                                {getCoachTitle({
                                  coachId:
                                    authUser?.id || authUser?.userId || "",
                                  usersForAvatars,
                                  specialties,
                                  specialtyCoaches
                                })}
                              </span>
                            </p>
                            {coachHasUnreadMessage(
                              messages,
                              authUser?.userId || authUser?.id || ""
                            ) && (
                              <Notification
                                className={styles.pinkBubble}
                                size="xs"
                                count={1}
                              />
                            )}
                          </div>
                        );
                      }
                    }}
                  />
                )}
              </>
            )}

            {notificationColor && !smsSettings?.useSMS && (
              <Notification
                className={notificationColor}
                size={"xs"}
                count={1}
              />
            )}
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              onClick={handleMinimizeWindow}
              className={minimized ? styles.minimized : ""}
              data-testid="message-window-minimize-button"
            >
              <img
                src={minimized ? ChevronDown : SubtractIcon}
                alt="mimimize"
              />
            </button>
            <button
              type="button"
              onClick={handleOnClose}
              className={minimized ? styles.minimized : ""}
            >
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
        </div>
        {shouldNotMentionElevance(groups, externalUser) && (
          <span className={styles.ibcClient}>
            {t("forbiddenMentions.noMentioningElevance")}
          </span>
        )}
        {showMessages && (
          <MessageList
            messages={filteredMessages}
            user={patient}
            optedOut={smsSettings?.useSMS && smsSettings?.userHasOptedOut}
            footerHidden={!shouldDisplayFooter}
            usersForAvatars={usersForAvatars}
            authUser={authUser}
            chosenManager={chosenManager}
            setMessageBeingRepliedTo={setMessageBeingRepliedTo}
            unreadMessages={unreadMessages}
            minimized={minimized}
            programThatUserIsIn={programThatUserIsIn}
          />
        )}
        {shouldDisplayFooter && (
          <MessageWindowFooter
            smsSettings={smsSettings}
            replyingToMessage={messageBeingRepliedTo}
          />
        )}
      </div>
      {showRecommendedReplies && (
        <CoachRecommendedMessageModal
          onSubmitMessage={handleSubmitMessage}
          setShowRecommendedReplies={setShowRecommendedReplies}
          userId={patient?.user?.userId}
          programId={programThatUserIsIn?.programCatalogItemId}
          locale={programThatUserIsIn?.locale}
          trackOnOpen={trackRecommendedMessagesViewed}
        />
      )}
    </MessageWindowContext.Provider>
  );
};

export default MessageWindow;
