import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ActOnInvitePage.module.scss";

import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { useActOnInvite } from "~/hooks/graphql/useInviteMutations";
import useUser from "~/hooks/useUser";

const ActOnInvitePage = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const {
    actOnInvite,
    isPending,
    isSuccess,
    isError,
    error
  } = useActOnInvite();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorDetails, setErrorDetails] = useState<string>();

  useEffect(() => {
    setErrorDetails(error?.message || "");
  }, [error]);

  // get query parameters from url
  const urlParams = new URLSearchParams(window.location.search);

  // get the value of the 'token' query parameter
  const token = urlParams.get("token");

  //Automatically accept the invite after the user has been created and authenticated.
  useEffect(() => {
    if (token && user?.id) {
      actOnInvite({ coachId: Number(user?.id), token });
    } else {
      setErrorMessage(t("accessManagement.errors.errorMissingToken"));
    }
  }, [token, actOnInvite, user?.id]);

  useEffect(() => {
    if (isSuccess) {
      window.location.href = "/programs";
    } else if (isError) {
      setErrorMessage(t("accessManagement.errors.errorAcceptingInvite"));
    }
  }, [isSuccess, isError]);

  return (
    <SentryErrorBoundary transactionName="actoninvite">
      <Layout>
        <div className={styles.wrapper}>
          {isPending && <h2>Please wait while we prepare your account...</h2>}
          {isError && (
            <div className={styles.error}>
              <p>{errorMessage}</p>
              <p className={styles.extraInfo}>
                {errorDetails && `Details: ${errorDetails}`}
                <br />
                User Id: {user?.id}
                <br />
                Token: {token}
              </p>
            </div>
          )}
          {isSuccess && <div>{t("invite.accepted")}</div>}
        </div>
      </Layout>
    </SentryErrorBoundary>
  );

  return null;
};

export default ActOnInvitePage;
