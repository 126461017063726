import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./PROListAnswer.module.scss";

import TranslationToggleBox from "~/components/translationToggleBox/TranslationToggleBox";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { TranslationsDto } from "~/typing/graphql/types";

type PROListAnswerProps = {
  defaultAnswer: JSX.Element;
  translationsLoading: boolean;
  translations?: TranslationsDto[];
  proIndex?: number;
  questionIndex?: number;
};

const PROListAnswer = ({
  defaultAnswer,
  translationsLoading,
  translations,
  proIndex,
  questionIndex
}: PROListAnswerProps) => {
  const { t } = useTranslation();
  const [showTranslated, setShowTranslated] = useState(true);
  const handleToggleTranslation = () => {
    setShowTranslated(!showTranslated);
  };
  const { autoTranslate } = useAutoTranslate();

  if (
    proIndex !== undefined &&
    questionIndex !== undefined &&
    translations !== undefined &&
    translations?.length >= proIndex
  ) {
    const translatedAnswer = translations[proIndex]?.translations?.find(
      (t) => t?.id === questionIndex
    )?.text;
    if (translatedAnswer && autoTranslate) {
      if (translationsLoading) {
        return <div>{t("translation.loading")}</div>;
      }
      return (
        <div
          key={`translated-${questionIndex}-${proIndex}`}
          className={styles.spacedContainer}
        >
          <div>{showTranslated ? translatedAnswer : defaultAnswer}</div>
          <TranslationToggleBox
            showTranslated={showTranslated}
            handleToggleTranslation={handleToggleTranslation}
          />
        </div>
      );
    }
  }
  return defaultAnswer;
};

export default PROListAnswer;
