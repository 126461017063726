import { DateTime } from "luxon";

import humanizeTimeDiff from "~/helpers/date/humanizeTimeDiff";
import { t } from "~/i18n";
import {
  CarePriorityType,
  ChurnRiskMetadata,
  FirstWeekMetadata,
  LastUnseenMessageInformation,
  ProgramProgressionMetadata
} from "~/typing/graphql/types";
import {
  CarePriorityData,
  MessageSuggestionType
} from "~/typing/sidekickTypes";

export const getCarePriorityMessageType = (type: CarePriorityType) => {
  switch (type) {
    case CarePriorityType.WeeklyFeedback:
      return MessageSuggestionType.WeeklyFeedback;
    case CarePriorityType.FirstWeek:
      return MessageSuggestionType.FirstWeek;
    case CarePriorityType.HighChurnRisk:
      return MessageSuggestionType.ChurnRisk;
    default:
      return undefined;
  }
};

const getHighChurnRiskMessage = (
  metadata: ChurnRiskMetadata,
  userName: string
): string => {
  const churnTime = humanizeTimeDiff(-metadata.millisSinceLastActivity);
  return t("nextStep.highChurnRisk.message", {
    userName,
    churnTime
  });
};

const getFirstWeekMessage = (
  metadata: FirstWeekMetadata,
  userName: string
): string => {
  let messageNumber = "";

  switch (metadata.numberOfMessagesReceived) {
    case 0:
      messageNumber = t("nextStep.firstWeek.firstMessage");
      break;
    case 1:
      messageNumber = t("nextStep.firstWeek.secondMessage");
      break;
    case 2:
      messageNumber = t("nextStep.firstWeek.thirdMessage");
      break;
    default:
      messageNumber = t("nextStep.firstWeek.messageDefault");
      break;
  }

  return t("nextStep.firstWeek.message", {
    userName,
    messageNumber,
    numberOfMessagesReceived: metadata.numberOfMessagesReceived
  });
};

const getNewMessagePriorityMessage = (
  metadata: LastUnseenMessageInformation,
  userName: string
): string => {
  if (!metadata.oldestUnreadMessageDate || metadata.unreadMessageCount === 0) {
    return t("nextStep.unseenMessage.emptyROR", { userName });
  }

  const dateTime = DateTime.fromISO(metadata.oldestUnreadMessageDate);
  const oldestUnreadMessageDate = `${dateTime.toLocaleString(
    DateTime.DATETIME_SHORT
  )}`;

  const diff = Math.max(
    Date.now() - DateTime.fromISO(metadata.oldestUnreadMessageDate).toMillis(),
    0
  );

  const unreadMessageTime = humanizeTimeDiff(diff);

  return t("nextStep.unseenMessage.message", {
    unreadMessageCount: metadata.unreadMessageCount,
    userName,
    unreadMessageTime,
    oldestUnreadMessageDate
  });
};

const getProgramProgressionMessage = (
  metadata: ProgramProgressionMetadata,
  userName: string
): string => {
  return t("nextStep.programProgression.message", {
    name: userName,
    module: metadata.moduleNumber,
    day: metadata.day
  });
};

export const getCarePriorityMessage = ({
  data,
  userName
}: {
  data: CarePriorityData;
  userName: string;
}): string => {
  switch (data.type) {
    case CarePriorityType.HighChurnRisk:
      return getHighChurnRiskMessage(data.metadata, userName);
    case CarePriorityType.FirstWeek:
      return getFirstWeekMessage(data.metadata, userName);
    case CarePriorityType.NewMessage:
      return getNewMessagePriorityMessage(data.metadata, userName);
    case CarePriorityType.ProgramProgression:
      return getProgramProgressionMessage(data.metadata, userName);
    default:
      return "";
  }
};
