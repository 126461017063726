import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./SurveyResultsModal.module.scss";

import AnswerBubble from "../../components/answerBubble/AnswerBubble";

import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import { shouldUseLightText } from "~/helpers/colours/contrast";
import getRatingBasedOnResult from "~/helpers/getRatingBasedOnResult";
import useSurveyResults from "~/hooks/useApi/useSurveyResults";
import colors from "~/styles/colors";
import { SurveyResultsModalDetails } from "~/typing/carePortalTypes";
import { Survey } from "~/typing/sidekickTypes";

type SurveyResultsModalProps = {
  programId: string;
  locale: string;
  selectedSurveyResult: SurveyResultsModalDetails;
  onClose: () => void;
  surveys: Survey[];
};

const SurveyResultsModal = ({
  programId,
  locale,
  selectedSurveyResult,
  onClose,
  surveys
}: SurveyResultsModalProps) => {
  const { displayName, columnTitle, userId, surveyId } = selectedSurveyResult;
  const survey = surveys?.find((survey) => survey.id === surveyId);
  const { t } = useTranslation();
  const { surveyResults, isLoading } = useSurveyResults({
    programCatalogItemId: programId,
    locale,
    userId,
    surveyId: surveyId ?? ""
  });

  const today = DateTime.local();
  const numDays = 10;
  const daysArray = Array.from(Array(numDays).keys())
    .reverse()
    .map((i) => today.minus({ days: i }));
  const renderDaysHeader = () => {
    return daysArray.map((day) => (
      <th className={styles.day} key={day.day}>
        {day.day}
      </th>
    ));
  };

  const prepareColumnData = () => {
    return daysArray.map((day) => {
      return surveyResults?.filter(
        (pro) => DateTime.fromISO(pro.date).toISODate() === day.toISODate()
      )?.[0];
    });
  };

  const prosPerDay = prepareColumnData();

  const getSurveyResultForQuestion = (pro, questionName) => {
    return pro?.questions?.find((question) => question.name === questionName);
  };

  const getUniqueValues = () => {
    const values: number[] = [];

    if (survey?.answerSeverities) {
      survey.answerSeverities.forEach((severity) => {
        values.push(severity.value);
      });
    } else {
      survey?.questions?.forEach((q) => {
        q.answers?.forEach((answer) => {
          if (!values.includes(answer.ratingValue)) {
            values.push(answer.ratingValue);
          }
        });
      });
    }
    return values.sort((a, b) => a - b);
  };

  const getAnswerSeverity = (id) => {
    return survey?.answerSeverities?.find((severity) => severity.id === id);
  };

  const uniqueValues = getUniqueValues();

  return (
    <Modal
      className={styles.modal}
      title={`${columnTitle}: ${displayName}`}
      onClose={onClose}
    >
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <table className={styles.table}>
            <thead>
              <tr className={styles.topRow}>
                <th className={styles.leftHeader}>{t("time.day")}</th>
                {renderDaysHeader()}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={styles.leftHeader}>{t("general.result")}</th>
                {prosPerDay?.map((pro, dayIndex) => {
                  const rating = getRatingBasedOnResult(
                    pro?.result ?? 0,
                    survey?.ratings
                  );
                  const classes = `${styles.resultBubble} ${
                    shouldUseLightText(rating) ? styles.lightText : ""
                  }`;

                  const style = {
                    background: rating?.colour || colors.navyBlue20
                  };

                  return (
                    <td key={dayIndex}>
                      <div className={styles.resultContainer}>
                        <div className={classes} style={style}>
                          {survey?.answerSeverities ? "" : pro?.result}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
              {survey?.questions?.map((question) => {
                return (
                  <tr key={question.id}>
                    <th className={styles.leftHeader}>{question.title}</th>
                    {prosPerDay?.map((pro, dayIndex) => (
                      <td key={dayIndex}>
                        <div className={styles.answerContainer}>
                          <AnswerBubble
                            question={getSurveyResultForQuestion(
                              pro,
                              question.name
                            )}
                            proExists={!!pro}
                            uniqueValues={uniqueValues}
                            small={true}
                            answerSeverities={survey?.answerSeverities}
                            getAnswerSeverity={getAnswerSeverity}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={styles.rowsContainer}>
            {surveyResults?.length === 0 &&
              t("providerView.symptomColumn.noSurveyResults")}
          </div>
        </>
      )}
    </Modal>
  );
};

export default SurveyResultsModal;
