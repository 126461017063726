import { useContext } from "react";

import styles from "./PROTemplateImage.module.scss";

import { PROTemplateContext } from "../../PROTemplate.context";

import SidekickEmptyState from "~/assets/SidekickEmptyState.svg";
import Image from "~/components/image/Image";
import SkeletonShimmer from "~/components/skeletons/SkeletonShimmer";
import { SurveyResultAnswer } from "~/typing/sidekickTypes";

type PROTemplateImageProps = {
  answer: SurveyResultAnswer | undefined;
  onImageClick: () => void;
};

const PROTemplateImage = ({ answer, onImageClick }: PROTemplateImageProps) => {
  const { proImages, setProImage } = useContext(PROTemplateContext);

  const onImageLoad = () => {
    if (!proImages.has(answer?.attachmentUrl ?? "")) {
      setProImage(answer?.attachmentUrl ?? "", "true");
    }
  };

  return answer?.attachmentUrl !== undefined ? (
    <>
      <Image
        dataTestId="pro-template-image"
        className={styles.image}
        imageUrl={answer?.attachmentUrl ?? ""}
        onClick={onImageClick}
        onImageLoad={onImageLoad}
        shouldFetchAsBlob={true}
      />
      {!proImages.has(answer.attachmentUrl) && (
        <div className={`skeleton-wrapper ${styles.image} ${styles.skeleton} `}>
          <SkeletonShimmer />
        </div>
      )}
    </>
  ) : (
    <div className={`${styles.sidekickEmptyStateDiv} skeleton-wrapper`}>
      <img
        src={SidekickEmptyState}
        alt=""
        className={`${styles.sidekickEmptyStateImage}`}
      />
    </div>
  );
};

export default PROTemplateImage;
