import styles from "./EmptyProContainer.module.scss";

import noteboard from "~/assets/noteboard.svg";
import { t } from "~/i18n";
import { SurveyQuestion } from "~/typing/sidekickTypes";

type EmptyPROContainerProps = {
  questionsToDisplay: SurveyQuestion[];
};

const EmptyPROContainer = ({ questionsToDisplay }: EmptyPROContainerProps) => {
  return (
    <div className={styles.emptyPROContainer}>
      <div className={styles.emptyPROQuestionContainer}>
        <div className={styles.emptyPROQuestionHeader}>
          <p className={styles.emptyPROQuestionHeaderTitle}>
            {t("pro.questions")}
          </p>
        </div>

        <div className={styles.emptyPROQuestionContent}>
          {questionsToDisplay?.map((question, index) => {
            return (
              <div
                key={`question-display-${question.id}-${index}}`}
                className={styles.emptyPROQuestionItem}
              >
                <p className={styles.emptyPROQuestionItemText}>
                  {question.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.emptyPROStateContainer}>
        <div className={styles.emptyPROStateTextFrame}>
          <p className={styles.emptyPROStateTextFrameUpperText}>
            {t("pro.noPROAnswerUpper")}
          </p>
          <p className={styles.emptyPROStateTextFrameLowerText}>
            {t("pro.noPROAnswerLower")}
          </p>
        </div>
        <div>
          <img src={noteboard} alt="Empty PRO placeholder image" />
        </div>
      </div>
    </div>
  );
};

export default EmptyPROContainer;
