export default {
  white: "#ffffff",

  navyBlue0: "#FCFCFC",
  navyBlue10: "#E5E8ED",
  navyBlue20: "#CCD2DC",
  navyBlue30: "#B2BCC4",
  navyBlue50: "#7F8FA7",
  navyBlue60: "#667996",
  navyBlue70: "#4C6284",
  navyBlue80: "#334d73",
  navyBlue100: "#002050",

  lightBlue20: "#CCECFF",
  lightBlue40: "#99D9FF",
  lightBlue100: "#009fff",
  lightBlue120: "#007FCC",
  lightBlue140: "#005F99",

  mustardYellow20: "#FFF5D2",
  mustardYellow40: "#FFECA6",
  mustardYellow80: "#FFD94D",
  mustardYellow100: "#FFCF20",
  mustardYellow120: "#997C13",
  mustardYellow140: "#997C13",
  mustardYellow200: "#332906",

  neonGreen10: "#E5FFF7",
  neonGreen40: "#99FFDF",
  neonGreen100: "#00FFAF",
  neonGreen120: "#00CC8C",
  neonGreen140: "#009969",
  neonGreen160: "#006646",
  neonGreen180: "#003323",

  neonPink40: "#FF99F2",
  neonPink100: "#FF00DF",
  neonPink140: "#990086",

  aqua20: "#D2F9FF",
  aqua120: "#1AB2CC",
  aqua140: "#138699",

  black: "#000000",

  darkBlue160: "#002066",
  darkBlue140: "#003099",
  darkBlue100: "#0050FF",
  darkBlue80: "#3373FF",
  darkBlue60: "#6696FF",

  red10: "#ffe6ec",
  red20: "#ffccd9",
  red40: "#FF99B3",
  red60: "#FF658D",
  red100: "#FF0040"
};
