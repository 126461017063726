import classNames from "classnames";

import styles from "./Heading.module.scss";

const cx = classNames.bind(styles);

type HeadingProps = {
  children: string;
  className?: string;
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const Heading = ({ children, className, level }: HeadingProps) => {
  const HeadingTag = level;
  return (
    <HeadingTag
      className={cx(styles.heading, {
        [className ?? ""]: !!className,
        [styles[level]]: true
      })}
    >
      {children}
    </HeadingTag>
  );
};

export default Heading;
