import styles from "./PROResultRow.module.scss";

import { SurveyResultToDisplay } from "../../hooks/useSurveyResultsToDisplay";
import ResultBubble from "../resultBubble/ResultBubble";

import getRatingBasedOnResult from "~/helpers/getRatingBasedOnResult";
import { t } from "~/i18n";
import { Survey } from "~/typing/sidekickTypes";

const PROResultRow = ({
  survey,
  hasRatings,
  surveyResultsToDisplay,
  uniqueValues,
  onCoachRatingChange
}: {
  surveyResultsToDisplay: SurveyResultToDisplay[];
  hasRatings: boolean;
  survey?: Survey;
  uniqueValues: number[];
  onCoachRatingChange: (
    coachRatingValue: number,
    surveyResultId: string,
    shouldUpdateDetails: boolean
  ) => void;
}) => {
  const getCoachRating = (pro: SurveyResultToDisplay) => {
    return survey?.ratings?.find((rating) => {
      return rating.coachRatingValue === pro.coachRatingValue;
    });
  };

  return (
    <tr className={styles.resultRow}>
      <th className={styles.resultTitle}>{t("general.result")}</th>

      {surveyResultsToDisplay.map((pro, index) => {
        const rating = getRatingBasedOnResult(pro.result, survey?.ratings);
        const coachRating = getCoachRating(pro);
        return (
          <td key={`result-bubble-pro-${pro.id}-${index}`}>
            <div className={styles.resultContainer} key={`result-${pro.id}`}>
              <ResultBubble
                rating={rating}
                coachRating={coachRating}
                pro={pro}
                onChangeRating={onCoachRatingChange}
                survey={survey}
                hasRatings={hasRatings ?? false}
                isLastPro={pro.isLatestSurvey}
                uniqueValues={uniqueValues}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default PROResultRow;
