import { useTranslation } from "react-i18next";

import styles from "./ProgramCard.module.scss";

import ProgramCardDetail from "../programCardDetail/ProgramCardDetail";

import LanguageIcon from "~/assets/language.svg";
import ActiveUsersIcon from "~/assets/users-active.svg";
import NewUsersIcon from "~/assets/users-new.svg";
import Image from "~/components/image/Image";
import Notification from "~/components/notification/Notification";
import config from "~/config";
import getCountryFlagSrc from "~/helpers/getCountryFlagSrc";
import getLanguageFromLocale from "~/helpers/getLanguageFromLocale";
import {
  getProgramDisplayName,
  isTestProgram
} from "~/helpers/program/programHelpers";
import CoachList from "~/pages/components/coachList/CoachList";
import { Program } from "~/typing/sidekickTypes";

type ProgramCardProps = {
  program: Program;
  notificationCount?: number;
};

const ProgramCard = ({ program, notificationCount }: ProgramCardProps) => {
  const {
    imageHref,
    location,
    locale,
    activeUsers,
    userCount,
    newUsers,
    tier,
    coaches,
    locales
  } = program;

  const { t } = useTranslation();

  const getLanguage = () => {
    const firstLanguage = getLanguageFromLocale(locale);

    if (locales && locales.length > 1) {
      const remainingLocales = locales.filter((loc) => loc !== locale);
      const [secondLocale, ...remainingLocalesRest] = remainingLocales;
      const secondLanguage = secondLocale
        ? getLanguageFromLocale(secondLocale)
        : "";
      const remainingCount = remainingLocalesRest.length;

      return remainingCount > 0
        ? `${firstLanguage}, ${secondLanguage} +${remainingCount}`
        : `${firstLanguage}, ${secondLanguage}`;
    }

    return firstLanguage;
  };

  const programCardDetailData = [
    {
      title: "country",
      content: location?.name,
      imgSrc: getCountryFlagSrc(location?.name),
      isCountry: true
    },
    {
      title: locales && locales.length > 1 ? "languages" : "language",
      content: getLanguage(),
      imgSrc: LanguageIcon,
      isCountry: false
    },
    {
      title: "activeUsers",
      content: (
        <>
          {activeUsers}
          <span>/{userCount}</span>
        </>
      ),
      imgSrc: ActiveUsersIcon,
      isCountry: false
    },
    {
      title: "newUsers",
      content: newUsers,
      imgSrc: NewUsersIcon,
      isCountry: false
    }
  ];

  const programCardDetailComponents = programCardDetailData.map(
    ({ title, content, imgSrc, isCountry }) => {
      return (
        <ProgramCardDetail
          title={title}
          key={title}
          content={content}
          className={styles.detail}
          imgSrc={imgSrc}
          isCountry={isCountry}
        />
      );
    }
  );

  return (
    <div className={styles.ProgramCard}>
      <Image
        className={config.isAnthem ? styles.imageAnthem : styles.image}
        imageUrl={imageHref}
        ribbonText={
          isTestProgram(program) ? t("programs.test").toUpperCase() : undefined
        }
      />
      <div
        data-testid="program-card-details"
        data-program-id={program.programCatalogItemId}
        className={styles.details}
      >
        <div className={styles.header}>
          <h3 className={styles.name}>{getProgramDisplayName(program)}</h3>
          <div className={`${styles.tier} ${styles[`tier-${tier}`]}`}>
            Tier {tier}
          </div>
        </div>
        <div className={styles.innerDetails}>{programCardDetailComponents}</div>
        <div className={styles.coaches}>
          <h5 className={styles.title}>
            {t("programCardDetail.careManagers")}
          </h5>
          <CoachList coaches={coaches} numToShow={3} />
        </div>
      </div>
      <Notification
        count={notificationCount}
        size="lg"
        className={styles.notification}
      />
    </div>
  );
};

export default ProgramCard;
