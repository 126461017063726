import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MessageSuggestionReport } from "./MessageSuggestionReport";
import styles from "./MessageSuggestionsDismissalModal.module.scss";
import MessageSuggestionToggle from "./MessageSuggestionToggle";

import Button from "../button/Button";
import Modal from "../modal/Modal";

import Copy from "~/assets/copy.svg";
import Check from "~/assets/svgComponents/Check";
import { ReportMessageProps } from "~/hooks/useApi/useCoachSuggestedMessages";
import colors from "~/styles/colors";
import { MessageSuggestionDto } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

type MessageSuggestionDismissalModalProps = {
  setShowDismissalModal: (showDismissalModal: boolean) => void;
  messages: MessageSuggestionDto[];
  userId: string;
  onCopyMessage?: (message: MessageSuggestionDto) => void;
  reportedMessageMap: Map<number, boolean>;
  fallbackMessage?: MessageSuggestionDto;
  handleMessageReported: (
    reportMessage: ReportMessageProps,
    index: number
  ) => void;
  reportIsPending: boolean;
};

export const MessageSuggestionDismissalModal = ({
  setShowDismissalModal,
  messages,
  userId,
  onCopyMessage,
  reportedMessageMap,
  fallbackMessage,
  handleMessageReported,
  reportIsPending: isPending
}: MessageSuggestionDismissalModalProps) => {
  const { t } = useTranslation();

  const [reportMessage, setReportMessage] = useState<ReportMessageProps>();

  const [copied, setCopied] = useState(false);

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const handleReportMessage = () => {
    if (!reportMessage) return;

    handleMessageReported(reportMessage, currentMessageIndex);
  };

  const canReportMessage = reportMessage?.note || reportMessage?.issues?.length;

  return (
    <Modal
      title="Unusable messages"
      onClose={() => setShowDismissalModal(false)}
      className={styles.modal}
    >
      <div className={styles.wrapper}>
        {fallbackMessage?.message ? (
          <div className={styles.fallbackMessageWrapper}>
            <p>{t("nextStep.messages.genericMessage.info")}</p>
            <div>
              <div className={styles.fallbackMessage}>
                {fallbackMessage.message}
              </div>
              <p className={styles.disclaimer}>
                {t("nextStep.messages.copyDisclaimer")}
              </p>
              <Button
                className={cx({
                  [styles.copyBtn]: true,
                  [styles.copied]: copied
                })}
                onClick={() => {
                  onCopyMessage?.(fallbackMessage);
                  setCopied(true);
                }}
              >
                {copied ? (
                  <Check color={colors.neonGreen180} />
                ) : (
                  <img src={Copy} alt="Copy" />
                )}
                {copied ? (
                  <p> {t("nextStep.messages.messageCopied")}</p>
                ) : (
                  <p>{t("nextStep.messages.copyMessage")}</p>
                )}
              </Button>
            </div>
          </div>
        ) : null}
        <section
          className={cx({
            [styles.suggestionsWrapper]: true,
            [styles.hidden]: !copied
          })}
        >
          <h4>{t("nextStep.helpUsFix")}</h4>
          <div className={styles.suggestions}>
            <MessageSuggestionReport
              message={messages[currentMessageIndex]}
              userId={userId}
              onReportMessageChange={setReportMessage}
              successfullyReported={reportedMessageMap.get(currentMessageIndex)}
            />
            <div className={styles.suggestionsActions}>
              <MessageSuggestionToggle
                onMessageIndexChange={setCurrentMessageIndex}
                messages={messages}
              />
              {!reportedMessageMap.get(currentMessageIndex) && (
                <Button
                  isLoading={isPending}
                  inverted
                  onClick={handleReportMessage}
                  disabled={!canReportMessage}
                >
                  {t("nextStep.report")}
                </Button>
              )}
            </div>
          </div>
          <div
            className={cx({
              [styles.dismissButton]: true,
              [styles.hidden]: !copied
            })}
          >
            <Button onClick={() => setShowDismissalModal(false)}>
              {t("general.done")}
            </Button>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default MessageSuggestionDismissalModal;
