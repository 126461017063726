import { useTranslation } from "react-i18next";

import styles from "./SymptomHighlightTooltip.module.scss";
import SymptomHighlightTooltipLog from "./SymptomHighlightTooltipLog";

import { SymptomLogDetails } from "~/typing/graphql/types";

type SymptomHighlightTooltipProps = {
  id: string;
  logs: SymptomLogDetails[];
};

const SymptomHighlightTooltip = ({
  id,
  logs
}: SymptomHighlightTooltipProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div>
            <p className={styles.modalHeaderTitle}>{id}</p>
          </div>
          <p className={styles.modalHeaderText}>
            {t("nextStep.diga.logsText")}
          </p>
        </div>
        <div className={styles.modalContent}>
          {logs.map((log: SymptomLogDetails, index: number) => (
            <SymptomHighlightTooltipLog
              severity={log?.severity ?? 0}
              time={log.loggedAt}
              key={index}
              newest={index === logs.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SymptomHighlightTooltip;
