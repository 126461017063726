import { useEffect, useRef, useState } from "react";

import styles from "./ResultTooltip.module.scss";

import DiscreteScale from "../../../../../../../components/discreteScale/DiscreteScale";

import { getBestRatingContrastTextColor } from "~/helpers/colours/contrast";
import {
  SurveyRating,
  SurveyResultAnswerSeverity
} from "~/typing/sidekickTypes";

type ResultTooltipProps = {
  ratings?: SurveyRating[];
  rating?: {
    id: string;
    colour: string;
    upperValue: number;
    lowerValue: number;
    value: number;
  };
  result?: number;
  useMaxSeverityAsResult?: boolean;
  uniqueValues?: number[];
  answerSeverities?: SurveyResultAnswerSeverity[];
};

export default function ResultTooltip({
  ratings,
  rating,
  result,
  useMaxSeverityAsResult,
  uniqueValues,
  answerSeverities
}: ResultTooltipProps) {
  const rangeRef = useRef<HTMLDivElement>(null);
  const ratingRef = useRef<HTMLDivElement>(null);
  const [resultDistance, setResultDistance] = useState(0);

  const isCurrentRating = (value) => {
    return value === rating?.value;
  };

  const calcResultDistance = () => {
    let percent = 0.75;
    if (!rating) {
      return percent;
    }
    const ratingWidth = ratingRef.current?.clientWidth || 0;
    const rangeWidth = rangeRef.current?.clientWidth || 0;
    const { lowerValue, upperValue } = rating;
    if (lowerValue !== undefined && upperValue !== undefined && result) {
      const range = upperValue - lowerValue;
      percent = (result - lowerValue) / range;
    }
    if (lowerValue === result) {
      percent = 0;
    }
    if (lowerValue !== undefined && upperValue === result) {
      percent = 1;
    }
    const distance = percent * (rangeWidth - ratingWidth / 2);
    setResultDistance(distance);
  };

  useEffect(() => {
    calcResultDistance();
  }, [ratings, rating, result]);

  const distanceStyle = { left: `${resultDistance}px` };
  const backgroundStyle = {
    background: rating?.colour,
    color: getBestRatingContrastTextColor(rating)
  };

  return (
    <div className={styles.ResultTooltip}>
      {useMaxSeverityAsResult ? (
        <DiscreteScale
          uniqueValues={uniqueValues ?? []}
          isCurrentRating={isCurrentRating}
          answerSeverities={answerSeverities}
        />
      ) : (
        <div className={styles.scale}>
          {ratings?.map((r) => {
            const isCorrespondingRating = r.id === rating?.id;

            const textStyle = {
              color: getBestRatingContrastTextColor(r)
            };

            return (
              <div
                className={styles.range}
                style={{ backgroundColor: r.colour }}
                key={r.id}
                ref={isCorrespondingRating ? rangeRef : null}
              >
                <div className={styles.lower} style={textStyle}>
                  {r.lowerValue}
                </div>
                {isCorrespondingRating && (
                  <div
                    className={styles.result}
                    style={distanceStyle}
                    ref={ratingRef}
                  >
                    <div
                      className={styles.border}
                      style={backgroundStyle}
                    ></div>
                    <div className={styles.text} style={backgroundStyle}>
                      {result}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
