import colors from "~/styles/colors";

export const Symptoms = [
  "Low mood",
  "Hot flushes",
  "Night sweats",
  "Sleep loss",
  "Stress",
  "Fatigue",
  "Brain fog",
  "Low sex drive",
  "Vaginal dryness",
  "Incontinence"
];

export enum SymptomSeverity {
  NotTracked = "Not tracked",
  Mild = "Mild",
  Moderate = "Moderate",
  Severe = "Severe",
  VerySevere = "Very severe"
}

export enum SymptomTrend {
  Normal,
  Severe,
  Improvement
}

export const SymptomColorMap = {
  NotTracked: colors.navyBlue20,
  Improvement: colors.neonGreen140,
  Severe: colors.red100,
  None: colors.navyBlue60
};
