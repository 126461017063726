import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import styles from "./Details.module.scss";

import { InformationPageContext } from "../informationPageContext";
import BaseWeightModal from "../modals/baseWeight/BaseWeightModal";

import { updateExternalUser } from "~/api/requests/externalUserRequests";
import ChevronDown from "~/assets/chevron-down.svg";
import PencilIcon from "~/assets/svgComponents/PencilIcon";
import Options from "~/components/options/Options";
import config from "~/config";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import states from "~/constants/states";
import {
  getPregnancyWeekFromDueDate,
  weeksSinceDate
} from "~/helpers/date/dateHelpers";
import getAge from "~/helpers/date/getAge";
import round from "~/helpers/number/round";
import { isMedicareProgram, programHasBehaviour } from "~/helpers/program/programHelpers";
import genderText from "~/helpers/string/genderText";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import kgToLbs from "~/helpers/units/kgToLbs";
import {
  getRetriggeredText,
  getRetriggeredValue,
  shouldNotMentionElevance
} from "~/helpers/user/userHelpers";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import useExternalUserGroups from "~/hooks/useApi/useExternalUserGroups";
import useUseMetric from "~/hooks/useUseMetric";
import { getRetriggerOptions } from "~/pages/program/registration/helper";
import {
  SettingNames,
  UserURLParams,
  OptionItem
} from "~/typing/carePortalTypes";
import { ExternalUser, UserDetails } from "~/typing/sidekickTypes";

interface DetailsProps {
  userDetails: UserDetails;
  language?: string;
  leftDate: string;
  riskFactor?: string;
  latestScaleDate?: string;
  scaleOrderStatus?: string;
  className?: string;
  showOrderScaleModal?: () => void;
  canCareManagerOrderDevice?: boolean;
}

type DetailData = {
  label: string;
  value: string | number | ReactNode;
  skip?: boolean;
  options?: OptionItem[];
  edit?: () => void;
  optionsDisabled?: boolean;
};

const BASE_PREGNANCY_WEEK = 11;

const Details = ({
  userDetails,
  latestScaleDate,
  scaleOrderStatus,
  className,
  showOrderScaleModal,
  leftDate,
  riskFactor,
  language
}: DetailsProps) => {
  const {
    gender,
    birthDate,
    joinedDate,
    canCareManagerOrderDevice
  } = userDetails;
  const [showBaseWeightModal, setShowBaseWeightModal] = useState(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { program_id = "", locale = "" } = useParams<UserURLParams>();
  const { baseWeight, baseWeightEnabled, settings, program } = useContext(
    InformationPageContext
  );

  const useMetric = useUseMetric();

  const programUsesScale = programHasBehaviour(
    program,
    ProgramBehaviour.UsingBodyTraceScale
  );

  const { externalUser, setManuallyInCache } = useExternalUser({
    externalUserId: userDetails?.externalUser?.id,
    externalUserTypeId: userDetails?.externalUser?.externalUserTypeId
  });
  const { groups } = useExternalUserGroups({
    disabled: !isMedicareProgram(program)
  });

  const saveCurrentExternalUser = async (
    update: ExternalUser,
    successText: string
  ) => {
    await updateExternalUser({
      programId: program_id,
      locale,
      externalUser: update
    })
      .then(() => {
        displaySuccessToast({ message: successText });
        setManuallyInCache(update);
        // Invalidate the user programs so that the user's coach list is updated
        queryClient.invalidateQueries({
          queryKey: QueryKeyFactory.programs.users(program_id, locale)
        });
      })
      .catch(() => displayErrorToast({ message: t("errors.generic") }));
  };

  const handleSaveUsState = async (state: string) => {
    if (!externalUser) return;

    saveCurrentExternalUser(
      { ...externalUser, memberState: state },
      t("user.details.stateUpdateSuccess")
    );
  };

  const handleSaveRetrigger = async (retrigger: boolean) => {
    if (!externalUser) return;

    saveCurrentExternalUser(
      { ...externalUser, eligibleForRestart: retrigger },
      t("user.details.retriggerUpdateSuccess")
    );
  };

  const getCurrentPregnancyWeek = () => {
    const pregnancyWeekSetting = settings?.find(
      (setting) => setting.name === SettingNames.PregnancyWeekInitialSetting
    );

    const babyBorn = settings?.find(
      (setting) => setting.name === SettingNames.BabyBorn
    );

    if (babyBorn?.value === "True") {
      return t("pregnancy.babyDelivered");
    }

    if (!pregnancyWeekSetting?.value) return 0;

    const pregnancyWeek =
      parseInt(pregnancyWeekSetting?.value) +
      weeksSinceDate(pregnancyWeekSetting?.modifiedDate) +
      BASE_PREGNANCY_WEEK;

    return `${t("time.week")} ${pregnancyWeek}`;
  };

  const detailData: DetailData[] = [
    {
      label: t("user.details.age", "Age"),
      value: birthDate ? getAge(birthDate) : "-"
    },
    {
      label: t("gender.gender", "Gender"),
      value: genderText(gender)
    },
    {
      label: t("user.details.contact", "Contact"),
      value: externalUser ? externalUser.phoneNumber : "-"
    },
    {
      label: t("general.language", "Language"),
      value: language || "-"
    },
    {
      label: t("user.details.riskFactor", "Risk Factor"),
      value: riskFactor || "Not set",
      skip: !config.isAnthem
    },
    {
      label: t("user.health.baseWeight", "Base weight"),
      value: baseWeight?.value
        ? `${
            useMetric
              ? round(baseWeight.value, 1) + " kg"
              : kgToLbs(baseWeight.value ?? 0) + " lbs"
          }`
        : "Not set",
      edit: () => setShowBaseWeightModal(true),
      skip: !baseWeightEnabled
    },
    {
      label: t("user.details.state", "State"),
      value:
        states.find((state) => state.code === externalUser?.memberState)
          ?.name || "Not set",

      skip: !config.isAnthem || !externalUser,
      options: states.map((state) => ({
        title: state.name,
        func: () => handleSaveUsState(state.code)
      }))
    },
    {
      label: t("user.details.joinTime", "Join Time"),
      value: joinedDate ? DateTime.fromISO(joinedDate).toLocaleString() : "-"
    },
    {
      label: t("user.details.leftDate", "Left Program"),
      value: leftDate || "-",
      skip: !leftDate
    },
    {
      label: "BodyTrace",
      value: (
        <p>
          {scaleOrderStatus || (
            <button className={styles.orderScale} onClick={showOrderScaleModal}>
              Order
            </button>
          )}
        </p>
      ),
      skip:
        canCareManagerOrderDevice ||
        !programUsesScale ||
        Boolean(latestScaleDate)
    },
    {
      label: "Scale last used",
      value: latestScaleDate
        ? DateTime.fromISO(latestScaleDate).toLocaleString()
        : "-",
      skip: !programUsesScale || !latestScaleDate
    },
    {
      label: t("pregnancy.pregnancy", "Pregnancy"),
      value: <p>{getCurrentPregnancyWeek()}</p>,
      skip: !getCurrentPregnancyWeek()
    },
    {
      label: t("pregnancy.pregnancyWeek", "Pregnancy Week"),
      value: (
        <p>
          Week{" "}
          {getPregnancyWeekFromDueDate(
            settings?.find(
              (setting) => setting.name === SettingNames.PregnancyDueDate
            )?.value
          )}
        </p>
      ),
      skip: !getPregnancyWeekFromDueDate(
        settings?.find(
          (setting) => setting.name === SettingNames.PregnancyDueDate
        )?.value
      )
    },
    {
      label: t("retrigger.retrigger", "Retrigger"),
      value: getRetriggeredText(
        externalUser?.eligibleForRestart,
        externalUser?.eligibleForRestartDate,
        program?.retriggeredStatusDurationSeconds
      ),
      skip:
        !externalUser ||
        !programHasBehaviour(program, ProgramBehaviour.RetriggeringEnabled),
      options: getRetriggerOptions().map((option) => ({
        title: option.text,
        func: () => handleSaveRetrigger(Boolean(parseInt(option.value)))
      })),
      optionsDisabled: !Boolean(
        parseInt(
          getRetriggeredValue(
            externalUser?.eligibleForRestart,
            externalUser?.eligibleForRestartDate,
            program?.retriggeredStatusDurationSeconds
          )
        )
      )
    },
    {
      label: t("retrigger.lastRetriggerDate", "Last Retrigger Date"),
      value: externalUser?.eligibleForRestartDate
        ? DateTime.fromISO(
            externalUser?.eligibleForRestartDate
          ).toLocaleString()
        : "-",
      skip:
        !externalUser ||
        !programHasBehaviour(program, ProgramBehaviour.RetriggeringEnabled)
    }
  ];

  return (
    <div
      data-testid="details-wrapper"
      className={`${styles.Details} ${className}`}
    >
      {detailData.map(
        ({ label, value, skip, edit, options, optionsDisabled }) => {
          if (skip) return null;
          return (
            <div className={styles.detail} key={label}>
              <span className={styles.label}>{`${label}:`}</span>
              {value}
              {label === t("user.details.contact") && shouldNotMentionElevance(groups, externalUser) &&
              <span className={styles.ibcClient}>({t("forbiddenMentions.noMentioningElevance")})</span>}
              {options && !optionsDisabled && (
                <Options
                  iconSrc={ChevronDown}
                  className={styles.options}
                  options={options}
                />
              )}
              {edit && (
                <button onClick={edit}>
                  <PencilIcon />
                </button>
              )}
            </div>
          );
        }
      )}
      {showBaseWeightModal && (
        <BaseWeightModal onClose={() => setShowBaseWeightModal(false)} />
      )}
    </div>
  );
};

export default Details;
