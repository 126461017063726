import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import config from "~/config";
import { SurveyResult } from "~/typing/sidekickTypes";

export default function useSurveyResults({
  programCatalogItemId,
  locale,
  userId,
  surveyId
}: {
  programCatalogItemId: string;
  locale: string;
  userId: string;
  surveyId: string;
}) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}/surveys/${surveyId}/surveyresults${
    config.isAnthem ? `?displayLocale=en` : ""
  }`;
  const { data, isError, isLoading, invalidate } = useApi<{
    items: SurveyResult[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.surveyResults(userId, surveyId),
    disabled: !userId || !surveyId
  });

  return {
    surveyResults: data?.items,
    isError,
    isLoading,
    invalidate
  };
}
