import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { SurveyResultToDisplay } from "./useSurveyResultsToDisplay";

import { fetchTranslations } from "~/api/requests/getTranslations";
import { SegmentationKey } from "~/constants/segmentationKeys";
import surveyQuestionDisplayTypes from "~/constants/surveyQuestionDisplayTypes";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import { PROURLParams } from "~/typing/carePortalTypes";
import {
  TranslationsDtoInput,
  TranslationsDto,
  TranslationDtoInput
} from "~/typing/graphql/types";

export const useSurveyResultTranslations = ({
  surveyResultsToDisplay
}: {
  surveyResultsToDisplay: SurveyResultToDisplay[];
}) => {
  const {
    user_id = "",
    survey_id = "",
    locale = ""
  } = useParams<PROURLParams>();

  const [translationsInput, setTranslationsInput] = useState<
    TranslationsDtoInput[]
  >([]);
  const [translations, setTranslations] = useState<TranslationsDto[]>([]);
  const [translationLoading, setTranslationLoading] = useState(true);

  const coachLanguage = "en"; // Hard coded for Elevance

  const { autoTranslate } = useAutoTranslate();

  const { isEnabled: messageTranslationSegment } = useFeatureToggle({
    featureName: SegmentationKey.MessageTranslationSegment,
    patientId: user_id
  });

  useEffect(() => {
    const loadTranslations = async () => {
      setTranslationLoading(true);

      try {
        const fetchedTranslations = await fetchTranslations({
          translationsInput,
          targetLanguage: coachLanguage,
          sourceLanguage: locale
        });

        setTranslations(fetchedTranslations);
      } catch (err) {
        console.error(err);
      } finally {
        setTranslationLoading(false);
      }
    };

    loadTranslations();
  }, [translationsInput]);

  useEffect(() => {
    if (messageTranslationSegment === undefined || !messageTranslationSegment) {
      return;
    }
    if (locale.split("-")[0] === coachLanguage.split("-")[0]) {
      return;
    }
    if (!surveyResultsToDisplay?.length || !autoTranslate) {
      return;
    }

    const translationsInput: TranslationsDtoInput[] = [];

    surveyResultsToDisplay?.forEach((pro) => {
      const translationArray: TranslationDtoInput[] = [];

      pro.questions?.forEach((question, index) => {
        if (question.displayType === surveyQuestionDisplayTypes.textInput) {
          translationArray.push({
            text: question.answers[0].answer,
            id: index
          });
        }
      });

      translationsInput.push({ translations: translationArray });
    });

    setTranslationsInput(translationsInput);
  }, [surveyResultsToDisplay, messageTranslationSegment, survey_id]);

  return { translations, translationLoading };
};
