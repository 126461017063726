import {
  useFloating,
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  Placement
} from "@floating-ui/react";
import {
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useClick
} from "@floating-ui/react";
import { useState, useRef } from "react";

const useCustomFloating = ({
  placement = "top",
  triggerOnClick = false
}: {
  placement: Placement;
  triggerOnClick?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({
        padding: 32
      }),
      shift({
        padding: 32
      }),
      arrow({
        element: arrowRef
      })
    ],
    whileElementsMounted: autoUpdate,
    placement
  });

  const hover = useHover(context, {
    move: !triggerOnClick,
    enabled: !triggerOnClick
  });
  const click = useClick(context, { enabled: triggerOnClick });
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    outsidePress: !triggerOnClick,
    referencePress: !triggerOnClick,
    enabled: true
  });
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    focus,
    dismiss,
    role
  ]);

  const extendedGetFloatingProps = () => ({
    ...getFloatingProps(),
    onClick: () => {
      if (triggerOnClick) {
        setIsOpen(false); // Close the tooltip on click
      }
    }
  });

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps: extendedGetFloatingProps,
    arrowRef,
    isOpen,
    setIsOpen,
    context
  };
};

export default useCustomFloating;
