import classNames from "classnames";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import EmptyPROContainer from "./components/emptyProContainer/EmptyProContainer";
import PROQuestionRow from "./components/proQuestionRow/PROQuestionRow";
import PROResultRow from "./components/proResultRow/PROResultRow";
import PROTemplateHeader from "./components/proTemplateHeader/PROTemplateHeader";
import { useSurveyQuestions } from "./hooks/useSurveyQuestions";
import { useSurveyResultsToDisplay } from "./hooks/useSurveyResultsToDisplay";
import { PROTemplateContext } from "./PROTemplate.context";
import { getUniqueValues } from "./PROTemplate.helpers";
import styles from "./PROTemplate.module.scss";

import { UserPageContext } from "../../../userPage/UserPage.context";

import { setCoachRatingForSurvey } from "~/api/requests/surveyRequests";
import Image from "~/components/image/Image";
import Modal from "~/components/modal/Modal";
import SkeletonPROPage from "~/components/skeletons/SkeletonPROPage";
import useSurveyResults from "~/hooks/useApi/useSurveyResults";
import { PROURLParams } from "~/typing/carePortalTypes";
import { Survey } from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

type PROTemplateProps = {
  survey: Survey | undefined;
  useTextBased: boolean;
};

const PROTemplate = ({ survey, useTextBased }: PROTemplateProps) => {
  const { userDetail, mutateUserDetail, userDetailLoading } = useContext(
    UserPageContext
  );

  const {
    program_id = "",
    locale = "",
    user_id = "",
    survey_id = ""
  } = useParams<PROURLParams>();

  const {
    invalidate: invalidateSurveyResults,
    isLoading: surveyResultsLoading,
    surveyResults
  } = useSurveyResults({
    locale,
    programCatalogItemId: program_id,
    userId: user_id,
    surveyId: survey_id
  });
  const { questions } = useSurveyQuestions({ survey });

  const [proImages, setProImages] = useState<Map<string, any>>(new Map());

  //Offset from the start of the PROResults list.
  const [offsetFromToday, setOffsetFromToday] = useState(0);
  const [answerInModal, setAnswerInModal] = useState<
    | {
        attachmentUrl?: string;
        date?: Date;
      }
    | undefined
  >();

  const hasRatings = survey?.ratings && survey.ratings.length > 1;

  const {
    surveyResultsToDisplay,
    isFirstDay,
    isNewestEntry,
    headerMonths
  } = useSurveyResultsToDisplay({
    surveyResults,
    offsetFromToday,
    useTextBased,
    userDetail
  });

  const updateCoachRating = async (
    coachRatingValue: number,
    surveyResultId: string,
    shouldUpdateDetails: boolean
  ) => {
    toast.remove();
    await setCoachRatingForSurvey({
      programId: program_id,
      userId: user_id,
      locale,
      surveyResultId,
      coachRatingValue
    }).then(() => {
      invalidateSurveyResults();
      if (shouldUpdateDetails) {
        mutateUserDetail();
      }
    });
  };

  const uniqueValues = getUniqueValues({ survey, questions });

  if (userDetailLoading || surveyResultsLoading) {
    return <SkeletonPROPage />;
  }

  return (
    <PROTemplateContext.Provider
      value={{
        proImages,
        setProImage: (key, image) => {
          proImages.set(key, image);
          setProImages(new Map(proImages));
        }
      }}
    >
      <Helmet title={survey?.surveyName} defer={false} />
      {surveyResultsToDisplay?.length ? (
        <div className={styles.PROTemplate}>
          <div
            className={cx(styles.results, {
              [styles.textResults]: useTextBased
            })}
          >
            <table className={styles.table}>
              <PROTemplateHeader
                headerMonths={headerMonths}
                offsetFromToday={offsetFromToday}
                surveyResultsToDisplay={surveyResultsToDisplay}
                useTextBased={useTextBased}
                isNewestEntry={isNewestEntry}
                setOffsetFromToday={setOffsetFromToday}
                isFirstDay={isFirstDay}
              />
              <tbody className={styles.tableBody}>
                <PROResultRow
                  hasRatings={hasRatings ?? false}
                  survey={survey}
                  onCoachRatingChange={updateCoachRating}
                  surveyResultsToDisplay={surveyResultsToDisplay}
                  uniqueValues={uniqueValues}
                />
                {questions?.map((question, index) => (
                  <PROQuestionRow
                    hasRatings={hasRatings ?? false}
                    question={question}
                    surveyResultsToDisplay={surveyResultsToDisplay}
                    uniqueValues={uniqueValues}
                    useTextBased={useTextBased}
                    key={`question-${question.id}-${index}`}
                    survey={survey}
                    setAnswerInModal={setAnswerInModal}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <EmptyPROContainer questionsToDisplay={questions ?? []} />
      )}
      {answerInModal && (
        <Modal
          title={`${
            answerInModal.date
              ? DateTime.fromISO(
                  answerInModal.date?.toISOString()
                ).toLocaleString(DateTime.DATE_HUGE)
              : ""
          }`}
          onClose={() => setAnswerInModal(undefined)}
        >
          <Image
            className={styles.proModalImage}
            dataTestId="pro-template-modal-image"
            imageUrl={answerInModal.attachmentUrl ?? ""}
          />
        </Modal>
      )}
    </PROTemplateContext.Provider>
  );
};

export default PROTemplate;
