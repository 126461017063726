import classNames from "classnames/bind";
import { DateTime } from "luxon";

import { getSeverityString } from "./SymptomHighlightHelpers";
import styles from "./SymptomHighlightTooltipLog.module.scss";

const cx = classNames.bind(styles);

type SymptomHighlightTooltipLogProps = {
  severity: number;
  time: string;
  newest: boolean;
};

const SymptomHighlightTooltipLog = ({
  severity,
  time,
  newest
}: SymptomHighlightTooltipLogProps) => {
  return (
    <div className={cx({ log: true, newest: newest })}>
      <div
        className={cx({
          [styles.severity]: true,
          none: severity === 0,
          mild: severity === 1,
          moderate: severity === 2,
          severe: severity === 3,
          verySevere: severity === 4
        })}
      >
        <p className={styles.severityText}>{severity}</p>
      </div>
      <div className={styles.info}>
        <p className={styles.infoSeverity}>{getSeverityString(severity)}</p>
        <p className={styles.infoTime}>
          {DateTime.fromFormat(time, "yyyy-MM-dd").toFormat("d. LLL.")}
        </p>
      </div>
    </div>
  );
};

export default SymptomHighlightTooltipLog;
