import { t } from "i18next";

import MessageSuggestion from "./MessageSuggestion";
import styles from "./MessageSuggestionWrapper.module.scss";

import PulseLoader from "../pulseLoader/PulseLoader";

import { useCoachSuggestedMessages } from "~/hooks/useApi/useCoachSuggestedMessages";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

type MessageSuggestionWrapperProps = {
  title?: string;
  messageType: MessageSuggestionType;
  numMessages: number;
  programId: string;
  locale: string;
  userId: string;
};

export const MessageSuggestionWrapper = ({
  title = t("messages.suggestedReply"),
  messageType,
  numMessages,
  programId,
  locale,
  userId
}: MessageSuggestionWrapperProps) => {
  const { messages, isLoading, isError } = useCoachSuggestedMessages({
    programId,
    locale,
    userId,
    numMessages: numMessages,
    messageType: messageType
  });

  if (isError)
    return (
      <div className={styles.wrapper}>
        <p>{t("nextStep.errorFetchingMessages")}</p>
      </div>
    );
  if (isLoading)
    return (
      <div className={styles.wrapper}>
        <PulseLoader inverted />
      </div>
    );

  return (
    <MessageSuggestion title={title} userId={userId} messages={messages} />
  );
};
