import React from "react";

import styles from "./InfoTooltip.module.scss";

import FloatingTooltip from "../floatingTooltip/FloatingTooltip";

import QuestionMark from "~/assets/question-mark.svg";

type InfoToolipProps = {
  content: React.ReactNode;
};

const InfoTooltip = ({ content }: InfoToolipProps) => {
  return (
    <FloatingTooltip tooltipContent={content}>
      <div className={styles.wrapper}>
        <img src={QuestionMark} />
      </div>
    </FloatingTooltip>
  );
};

export default InfoTooltip;
