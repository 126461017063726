import styles from "./NarrowLayout.module.scss";

import Heading from "../heading/Heading";

import NavHeader from "~/components/nav-header/NavHeader";

type NarrowLayoutProps = {
  children: any;
  title?: string;
  titleImg?: string;
};

const NarrowLayout = ({ children, title, titleImg }: NarrowLayoutProps) => {
  return (
    <>
      <NavHeader />
      <div className={styles.NarrowLayout}>
        <div className={styles.header}>
          {titleImg && (
            <img src={titleImg} alt="Decorative logo" height="24" width="24" />
          )}
          {title && (
            <Heading level="h2" className={styles.heading}>
              {title}
            </Heading>
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default NarrowLayout;
