import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getLatestSurveyResult } from "../PROTemplate.helpers";

import useWindowSize from "~/hooks/useWindowSize";
import { SurveyResult, UserDetails } from "~/typing/sidekickTypes";

//Allowed window sizes. Used to determine how many items are displayed.
const windowSizes = [
  1440,
  1384,
  1328,
  1272,
  1216,
  1160,
  1104,
  1048,
  992,
  936,
  880
];

export type SurveyResultToDisplay = SurveyResult & {
  dateTime: DateTime;
  isLatestSurvey: boolean;
};

export const useSurveyResultsToDisplay = ({
  surveyResults,
  useTextBased,
  userDetail,
  offsetFromToday
}: {
  surveyResults?: SurveyResult[];
  useTextBased: boolean;
  userDetail?: UserDetails;
  offsetFromToday: number;
}) => {
  const [surveyResultsToDisplay, setSurveyResultsToDisplay] = useState<
    SurveyResultToDisplay[] | undefined
  >();

  //Map of months that are supposed to be displayed in the header. E.g. Aug 2022, Dec 2022, etc.
  //The key is the index in the surveyResultsToDisplay list above which the month should be displayed.
  //The value is the string to be displayed.
  const [headerMonths, setHeaderMonths] = useState<Map<number, string>>(
    new Map()
  );
  const [isNewestEntry, setIsNewestEntry] = useState(false);
  const [isFirstDay, setIsFirstDay] = useState(false);

  const { width, screenWidth } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    setSurveyResultsToDisplay(undefined);
  }, [location]);

  useEffect(() => {
    if (!surveyResults?.length || !userDetail) {
      setSurveyResultsToDisplay(undefined);
      return;
    }

    let numCols = useTextBased ? 2 : 10;

    if (!useTextBased) {
      windowSizes.forEach((size) => {
        if (
          ((width && width < size) || (screenWidth && screenWidth < size)) &&
          numCols > 1
        ) {
          numCols = numCols - 1;
        }
      });
    }

    const lastSurveyResult = getLatestSurveyResult(surveyResults ?? []);

    //Reverse the list so that it is displayed correctly and add the date in the correct timezone.
    const finalList: SurveyResultToDisplay[] = surveyResults
      .slice(offsetFromToday, offsetFromToday + numCols)
      .reverse()
      .map((item) => ({
        ...item,
        isLatestSurvey: item.id === lastSurveyResult?.id,
        dateTime: DateTime.fromISO(item.localDate ?? item.date) // Use the users local timezone to display the date, if available
      }));
    setSurveyResultsToDisplay(finalList);
  }, [surveyResults, useTextBased, userDetail, width, offsetFromToday]);

  /**
   * Sets the check for if the last entry in the display list is the value for today.
   * Compares the value gotten from the backend with the current value in the display list.
   */
  useEffect(() => {
    if (!surveyResultsToDisplay?.length) return;

    setIsNewestEntry(
      surveyResultsToDisplay?.[surveyResultsToDisplay.length - 1].id ===
        surveyResults?.[0].id
    );
    setIsFirstDay(
      surveyResultsToDisplay?.[0].id ===
        surveyResults?.[surveyResults.length - 1].id
    );

    const monthHeaderMap = new Map<number, string>();

    //Check for other months in the display list.
    surveyResultsToDisplay?.forEach((pro, index) => {
      const entry = DateTime.fromISO(pro.date, {
        zone: "utc"
      }).toLocaleString({
        month: "short",
        year: "numeric"
      });

      if (!Array.from(monthHeaderMap.values()).includes(entry)) {
        monthHeaderMap.set(index, entry);
      }
    });

    setHeaderMonths(monthHeaderMap);
  }, [surveyResultsToDisplay]);

  return {
    surveyResultsToDisplay,
    headerMonths,
    isNewestEntry,
    isFirstDay
  };
};
