import { useEffect, useState } from "react";

import styles from "./MessageSuggestionToggle.module.scss";

import ChevronLeft from "~/assets/chevron-left-next-step.svg";
import ChevronRight from "~/assets/chevron-right-next-step.svg";
import RefreshIcon from "~/assets/refresh-next-step.svg";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { MessageSuggestionDto } from "~/typing/graphql/types";

type MessageSuggestionToggleProps = {
  simulateMessageGeneration?: boolean;
  messages: MessageSuggestionDto[];
  onMessageIndexChange: (index: number) => void;
};

const MessageSuggestionToggle = ({
  simulateMessageGeneration,
  messages,
  onMessageIndexChange
}: MessageSuggestionToggleProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [messagesToDisplay, setMessagesToDisplay] = useState<
    MessageSuggestionDto[]
  >(simulateMessageGeneration ? messages.slice(0, 1) : messages);

  const { trackMessageSuggestionChanged } = useAmplitudeTracking();

  const handleMessageChange = (dir: "left" | "right") => {
    if (dir === "left") {
      setCurrentMessageIndex(currentMessageIndex - 1);
      trackMessageSuggestionChanged({
        from: messagesToDisplay[currentMessageIndex]?.suggestionId,
        to: messagesToDisplay[currentMessageIndex - 1]?.suggestionId
      });
    } else {
      setCurrentMessageIndex(currentMessageIndex + 1);
      trackMessageSuggestionChanged({
        from: messagesToDisplay[currentMessageIndex]?.suggestionId,
        to: messagesToDisplay[currentMessageIndex + 1]?.suggestionId
      });
    }
  };

  const handleRefresh = () => {
    setCurrentMessageIndex((prev) => prev + 1);
    setMessagesToDisplay(messages);
  };

  useEffect(() => {
    onMessageIndexChange(currentMessageIndex);
  }, [currentMessageIndex]);

  if (!messages.length) return null;

  if (
    simulateMessageGeneration &&
    messagesToDisplay.length === 1 &&
    messages.length > 1
  ) {
    return (
      <button className={styles.button} onClick={handleRefresh}>
        <img src={RefreshIcon} alt="Refresh" />
        <p>{t("nextStep.messages.generateNew")}</p>
      </button>
    );
  }

  if (messages.length < 2) return <div />;

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.button}
        disabled={currentMessageIndex === 0}
        onClick={() => handleMessageChange("left")}
      >
        <img src={ChevronLeft} alt="Left" />
      </button>
      {currentMessageIndex + 1} / {messagesToDisplay.length}
      <button
        className={styles.button}
        disabled={currentMessageIndex === messagesToDisplay.length - 1}
        onClick={() => handleMessageChange("right")}
      >
        <img src={ChevronRight} alt="Right" />
      </button>
    </div>
  );
};

export default MessageSuggestionToggle;
