import { BehavioralPROAnswerData } from "~/typing/carePortalTypes";

export enum BehavioralPROStatusType {
  Struggling = "Struggling",
  Managing = "Managing",
  Thriving = "Thriving"
}

export const mapBehavioralPROQuestion = (
  question: string
): BehavioralPROAnswerData => {
  switch (question) {
    case "exercise":
      return {
        title: "Exercise",
        description:
          "In the past six weeks, how often, in an average week, did you engage in structured exercise (e.g., walking, running, cycling, weight lifting, exercise classes, etc.) ?",
        values: [
          "Never",
          "1-2 times",
          "3-4 times",
          "5-6 times",
          "7-8 times",
          "9-10 times",
          "11 or more"
        ],
        shortValues: [
          "Never",
          "1-2 times",
          "3-4 times",
          "5-6 times",
          "7-8 times",
          "9-10 times",
          "11 or more"
        ]
      };
    case "goalSetting":
      return {
        title: "Goal Setting",
        description:
          "In the past six weeks, how regularly have you created goals and followed a plan to achieve them ?",
        values: [
          "Never",
          "Rarely",
          "Occasionally",
          "Sometimes",
          "Often",
          "Very Often",
          "Always"
        ],
        shortValues: [
          "Never",
          "Rarely",
          "Occasionally",
          "Sometimes",
          "Often",
          "Very Often",
          "Always"
        ]
      };
    case "manageNegativity":
      return {
        title: "Cognitive Restructuring",
        description:
          "In the past six weeks, how well have you been able to manage negative thoughts and emotions in relation to menopause ?",
        values: [
          "Not at all Well",
          "Slightly Well",
          "Somewhat Well",
          "Moderately Well",
          "Fairly Well",
          "Very Well",
          "Extremely Well"
        ],
        shortValues: [
          "Not at all",
          "Slightly",
          "Somewhat",
          "Moderately",
          "Fairly",
          "Very",
          "Extremely"
        ]
      };
    case "menoKnowledge":
      return {
        title: "Education",
        description:
          "How knowledgeable do you feel about menopause and its symptoms ?",
        values: [
          "Not knowledgeable at all",
          "Slightly knowledgeable",
          "Somewhat knowledgeable",
          "Moderately knowledgeable",
          "Fairly knowledgeable",
          "Very knowledgeable",
          "Extremely knowledgeable"
        ],
        shortValues: [
          "Not at all",
          "Slightly",
          "Somewhat",
          "Moderately",
          "Fairly",
          "Very",
          "Extremely"
        ]
      };
    case "practiceMindfulness":
      return {
        title: "Mindfulness / Relaxation Techniques",
        description:
          "In the past six weeks, how many times per week do you practice mindfulness or relaxation techniques such as deep breathing or meditation ?",
        values: [
          "Never",
          "1-2 times",
          "3-4 times",
          "5-6 times",
          "7-8 times",
          "9-10 times",
          "11 or more times"
        ],
        shortValues: [
          "Never",
          "1-2 times",
          "3-4 times",
          "5-6 times",
          "7-8 times",
          "9-10 times",
          "11 or more"
        ]
      };
    default:
      return {
        title: "",
        description: "",
        values: [],
        shortValues: []
      };
  }
};
