import { SegmentationKey } from "~/constants/segmentationKeys";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";

type SegmentationProps = {
  featureName: SegmentationKey;
  patientId?: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const Segmentation = ({
  featureName,
  patientId,
  children,
  fallback
}: SegmentationProps) => {
  const { isEnabled } = useFeatureToggle({ featureName, patientId });

  return <>{isEnabled ? children : fallback || null}</>;
};
