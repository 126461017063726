import { useEffect, useState } from "react";
import { z } from "zod";

import AddCareManagerForm from "./AddCareManagerForm";
import {
  AddCareManagersFormContext,
  defaultErrorState
} from "./AddCareManagerModal.context";
import { getInformationFromAvailablePrograms } from "./AddCareManagerModal.helpers";
import styles from "./AddCareManagerModal.module.scss";

import ErrorScreen from "../error/ErrorScreen";
import PreviewForm from "../previewForm/PreviewForm";
import SuccessScreen from "../success/SuccessScreen";

import ModalWithFooterActions from "~/components/modal/ModalWithFooterActions";
import { useInviteCareManagers } from "~/hooks/graphql/useInviteMutations";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { CareManagerWithPcIs } from "~/typing/graphql/types";
import { Program } from "~/typing/sidekickTypes";

const profileSchema = z.object({
  emails: z
    .array(
      z
        .string({ required_error: "Email is required" })
        .email("This list included invalid e-mails")
    )
    .nonempty({ message: "Email is required" }),
  locales: z
    .array(z.string({ required_error: "Locale is required" }))
    .nonempty({ message: "Locale is required" }),
  therapeuticAreas: z.array(z.string()),
  locations: z.array(z.string()),
  message: z.string(),
  programs: z.array(z.string()).nonempty({ message: "Program is required" })
});

type AddCareManagerModalProps = {
  userToInvite?: CareManagerWithPcIs;
  onClose: () => void;
  programsAvailable?: Program[];
  onSuccess: () => void;
};

enum AddCareManagerStep {
  Form = "Form",
  Preview = "Preview",
  Success = "Success",
  Error = "Error"
}

const AddCareManagerModal = ({
  onClose,
  userToInvite,
  onSuccess,
  programsAvailable
}: AddCareManagerModalProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [locales, setLocales] = useState<string[]>([]);
  const [therapeuticAreas, setTherapeuticAreas] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [message] = useState("");
  const [programs, setPrograms] = useState<string[]>([]);
  const [errors, setErrors] = useState(defaultErrorState);
  const [currentStep, setCurrentStep] = useState<AddCareManagerStep>(
    AddCareManagerStep.Form
  );

  const { user } = useUser();

  const {
    inviteCareManagers,
    isPending: invitePending,
    isSuccess: inviteSuccess,
    isError: inviteError
  } = useInviteCareManagers();

  const {
    locales: possibleLocales,
    locations: possibleLocations,
    therapeuticAreas: possibleTherapeuticAreas
  } = getInformationFromAvailablePrograms(programsAvailable);

  const createProgramCatalogItemLocaleDto = () => {
    return programs
      .map((program) => {
        return locales.map((locale) => ({
          programCatalogItemId: program,
          locale
        }));
      })
      .flat();
  };

  const handleForwardButtonClick = () => {
    if (currentStep === AddCareManagerStep.Form) {
      const result = profileSchema.safeParse({
        emails: userToInvite ? [userToInvite.careManager?.email] : emails,
        locales,
        therapeuticAreas,
        locations,
        message,
        programs
      });

      if (result.success) {
        setCurrentStep(AddCareManagerStep.Preview);
      } else {
        const tempErrors = { ...defaultErrorState };
        result.error.issues.forEach((issue) => {
          tempErrors[issue.path[0]] = issue.message;
        });
        setErrors(tempErrors);
      }
    } else if (currentStep === AddCareManagerStep.Preview) {
      inviteCareManagers({
        customText: message,
        emails: userToInvite ? [userToInvite.careManager?.email] : emails,
        programCatalogItemLocaleDtos: createProgramCatalogItemLocaleDto(),
        role: "Test role",
        senderCoachId: user?.id
      });
    } else {
      onClose();
    }
  };

  const handleBackClick = () => {
    if (currentStep === AddCareManagerStep.Preview) {
      setCurrentStep(AddCareManagerStep.Form);
    } else {
      onClose();
    }
  };

  const getNextButtonText = () => {
    if (currentStep === AddCareManagerStep.Form) {
      return t("accessManagement.reviewInvite");
    }
    if (currentStep === AddCareManagerStep.Preview) {
      return t("general.submit");
    }
    return t("general.close");
  };

  useEffect(() => {
    if (inviteSuccess) {
      setCurrentStep(AddCareManagerStep.Success);
      onSuccess(); //onSuccess is a function passed to the component. Will refetch the data.
    } else if (inviteError) {
      setCurrentStep(AddCareManagerStep.Error);
    }
  }, [inviteSuccess, inviteError]);

  return (
    <ModalWithFooterActions
      overflowHidden={false}
      title="Add care manager(s)"
      onClose={onClose}
      className={`${styles.modal}`}
      actions={[
        {
          label:
            currentStep === AddCareManagerStep.Preview
              ? t("general.back")
              : t("general.cancel"),
          onClick: handleBackClick,
          inverted: true,
          hidden: currentStep === AddCareManagerStep.Success,
          disabled: invitePending
        },
        {
          label: getNextButtonText(),
          onClick: handleForwardButtonClick,
          disabled: invitePending
        }
      ]}
    >
      <AddCareManagersFormContext.Provider
        value={{
          userToInvite,
          chosenEmails: emails,
          availableLocales: possibleLocales,
          chosenLocales: locales,
          chosenTherapeuticAreas: therapeuticAreas,
          availableTherapeuticAreas: possibleTherapeuticAreas,
          availableLocations: possibleLocations,
          chosenLocations: locations,
          // message,
          chosenPrograms: programs,
          availablePrograms: programsAvailable ?? [],
          setEmails,
          setLocales,
          setTherapeuticAreas,
          setLocations,
          // setMessage,
          setPrograms,
          errors
        }}
      >
        {currentStep === AddCareManagerStep.Form && <AddCareManagerForm />}
        {currentStep === AddCareManagerStep.Preview && (
          <PreviewForm
            selectedCareManager={userToInvite}
            data={[
              {
                heading: t("general.careManagers"),
                values: emails.map((email) => ({ renderOption: () => email })),
                hidden: !!userToInvite
              },
              {
                heading: t("accessManagement.speaking"),
                values: locales.map((locale) => ({
                  renderOption: () =>
                    possibleLocales.find((l) => l.locale === locale)?.name
                }))
              },
              {
                heading: t("accessManagement.atLocation"),
                values: locations.map((location) => ({
                  renderOption: () =>
                    possibleLocations.find((l) => l.id === location)?.name
                }))
              },
              {
                heading: t("accessManagement.invitedTo"),
                values: programs
                  .map((program) =>
                    programsAvailable?.find(
                      (p) => p.programCatalogItemId === program
                    )
                  )
                  .map((program) => ({
                    renderOption: () => program?.name
                  }))
              }
              // {
              //   heading: t("general.message"),
              //   values: [{ renderOption: () => message }]
              // }
            ]}
          />
        )}
        {currentStep === AddCareManagerStep.Error && (
          <ErrorScreen text={t("accessManagement.errors.errorInvite")} />
        )}
        {currentStep === AddCareManagerStep.Success && (
          <SuccessScreen text={t("accessManagement.successfulInvite")} />
        )}
      </AddCareManagersFormContext.Provider>
    </ModalWithFooterActions>
  );
};

export default AddCareManagerModal;
