import { DateTime } from "luxon";
import { useRef, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

import styles from "./exportPreview.module.scss";
import { ExportContext } from "./exportPreviewContext";
import { ExportCategories, getInformationIncludedArray } from "./exportUtils";
import PrintUserReport from "./PrintUserReport";

import mentalReducer, {
  MentalActions
} from "../performance/mental/mentalReducer";
import fitnessTestReducer, {
  FitnessActions
} from "../performance/physical/fitnessTestReducer";

import api from "~/api/api";
import CopyIcon from "~/assets/pages.svg";
import NoDataSidekick from "~/assets/sidekick-shrugging.png";
import Button from "~/components/button/Button";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import CustomDatePicker from "~/components/customDatePicker/CustomDatePicker";
import Modal from "~/components/modal/Modal";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import { displaySuccessToast } from "~/helpers/toast/displayToast";
import {
  foodJournalText,
  messagesText,
  notesText,
  proText,
  performanceText
} from "~/helpers/toTextHelpers";
import { useSmsMessages } from "~/hooks/useApi/messages/useSmsMessages";
import useCommunicationPreferences from "~/hooks/useApi/useCommunicationPreferences";
import useMissions from "~/hooks/useApi/useMissions";
import useMissionSummary from "~/hooks/useApi/useMissionSummary";
import useSurveys from "~/hooks/useApi/useSurveys";
import useUseMetric from "~/hooks/useUseMetric";
import { useUserNotes } from "~/hooks/useUserNotes";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  ChatMessage,
  Coach,
  Program,
  UserDetails
} from "~/typing/sidekickTypes";

type ExportPreviewProps = {
  close: () => void;
  coach?: Coach;
  detail: UserDetails;
  leftDate: string;
  timeZoneOffset: number;
  program?: Program;
  language?: string;
};

const DEFAULT_CATEGORIES = Object.values(ExportCategories);

const ExportPreview = ({
  close,
  detail,
  coach,
  program,
  language,
  timeZoneOffset,
  leftDate
}: ExportPreviewProps) => {
  const { t } = useTranslation();
  const useMetric = useUseMetric();
  const { program_id = "", locale = "", user_id = "" } = useParams<{
    program_id: string;
    locale: string;
    user_id: string;
  }>();

  const { trackExport, trackUserReportCopied } = useAmplitudeTracking();

  const [selectedCategories, setSelectedCategories] = useState<
    ExportCategories[]
  >(DEFAULT_CATEGORIES);

  const [startDate, setStartDate] = useState(
    DateTime.now()
      .minus({ month: 1 })
      .set({ hour: 0, minute: 0, second: 0 })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState<Date>();

  const { smsMessages } = useSmsMessages({
    locale,
    programCatalogItemId: program_id,
    externalUser: detail?.externalUser
  });

  const { missions } = useMissions();
  const { communicationPreferences } = useCommunicationPreferences(user_id);
  const fitnessTestMission = missions?.find(
    (mission) => mission.imageName === "mission_fitness_test"
  );
  const [isLoading, setIsLoading] = useState(false);

  const [fitnessTestData, dispatchFitnessData] = useReducer(
    fitnessTestReducer,
    []
  );
  const [sleepTestData, dispatchSleepData] = useReducer(mentalReducer, []);
  const [stressTestData, dispatchStressData] = useReducer(mentalReducer, []);
  const [energyTestData, dispatchEnergyData] = useReducer(mentalReducer, []);

  const getMissionByImageName = (missionImageName) =>
    missions?.find((mission) => mission.imageName === missionImageName);

  const getMissionData = async (missionImageName) => {
    const mission = getMissionByImageName(missionImageName);
    if (mission) {
      return await api.get(
        `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/scores?missionId=${
          mission.id
        }${startDate ? `&startDate=${startDate.toISOString()}` : ""}${
          endDate ? `&endDate=${endDate.toISOString()}` : ""
        }`
      );
    } else {
      return { data: { items: [] } };
    }
  };

  const toggleCategory = (category: ExportCategories) => {
    const index = selectedCategories.indexOf(category);
    const copiedList = [...selectedCategories];

    if (index >= 0) {
      copiedList.splice(index, 1);
    } else {
      copiedList.push(category);
    }

    setSelectedCategories(copiedList);
  };

  const getSpecificMissions = () => {
    getSpecificMissionData("mission_fitness_test");
    getSpecificMissionData("mission_sleep_quality");
    getSpecificMissionData("mission_stress_levels");
    getSpecificMissionData("mission_energy_levels");
  };

  const getSpecificMissionData = async (missionImageName) => {
    const res = await getMissionData(missionImageName);
    const mentalDispatchData = {
      type: MentalActions.Fetch,
      payload: res?.data?.items || []
    };
    switch (missionImageName) {
      case "mission_fitness_test":
        dispatchFitnessData({
          payload: res?.data?.items || [],
          useMetric: useMetric,
          type: FitnessActions.Fetch
        });
        break;
      case "mission_sleep_quality":
        dispatchSleepData(mentalDispatchData);
        break;
      case "mission_stress_levels":
        dispatchStressData(mentalDispatchData);
        break;
      case "mission_energy_levels":
        dispatchEnergyData(mentalDispatchData);
        break;
    }
  };

  useEffect(() => {
    getSpecificMissions();
  }, [fitnessTestMission]);

  const performanceData = detail?.dailySummary
    .map((item) => {
      return {
        ...item,
        date: DateTime.fromISO(item.date)
      };
    })
    .reverse();

  const { surveys } = useSurveys({ programCatalogItemId: program_id, locale });

  const [surveysData, setSurveysData] = useState({});

  const fetchSurveyResultData = async () => {
    const newSurveysData = {};

    for (const survey of surveys) {
      const surveyRes = await api.get(
        `/coach/programs/${program_id}/locales/${locale}/users/${user_id}/surveys/${
          survey.id
        }/surveyresults?${
          startDate ? `&oldestDate=${startDate.toISOString()}` : ""
        }${endDate ? `&endDate=${endDate.toISOString()}` : ""}`
      );

      newSurveysData[survey.surveyName] = surveyRes?.data?.items.map(
        (item) => ({
          ...item,
          quiz: survey.quiz
        })
      );
    }

    setSurveysData(newSurveysData);
  };

  const { slotDays } = useMissionSummary({
    programId: program_id,
    locale,
    userId: user_id,
    startDate: DateTime.fromJSDate(startDate).toISODate() ?? "",
    endDate: endDate
      ? DateTime.fromJSDate(endDate).toISODate() ?? undefined
      : undefined
  });

  useEffect(() => {
    fetchSurveyResultData();
  }, [surveys]);

  const printUserReportRef = useRef(null);

  const linkToPrint = () => {
    return (
      <Button isLoading={isLoading} disabled={selectedCategories.length == 0}>
        {t("exportPreview.print", "Print")}
      </Button>
    );
  };

  const { notes } = useUserNotes();

  const getMessageSenderName = (msg: ChatMessage): string => {
    if (msg.senderUserId === user_id) {
      return detail.user.fullName;
    }

    const coachThatSentMessage = program?.coaches.find((c) =>
      msg.replyingUserId
        ? c.userId === msg.replyingUserId
        : c.userId === msg.senderUserId
    );

    return coachThatSentMessage
      ? coachThatSentMessage?.displayName ?? ""
      : config.isAnthem
      ? t("exportPreview.caremanager")
      : t("exportPreview.coach");
  };

  const handleCopyToClipboard = () => {
    let text = "";
    text += selectedCategories.includes(ExportCategories.FoodJournal)
      ? foodJournalText(detail?.scoremeals, 0, startDate, endDate)
      : "";
    text += selectedCategories.includes(ExportCategories.MessagesSMS)
      ? messagesText({
          messages: detail?.messageThreads?.[0]?.messages ?? [],
          getMessageSenderName,
          startDate,
          endDate,
          isSms: false
        })
      : "";
    text += selectedCategories.includes(ExportCategories.MessagesSMS)
      ? messagesText({
          messages: smsMessages,
          getMessageSenderName,
          startDate,
          endDate,
          isSms: true
        })
      : "";
    text += selectedCategories.includes(ExportCategories.Notes)
      ? notesText(notes, startDate, endDate)
      : "";
    text += selectedCategories.includes(ExportCategories.PRO)
      ? proText(surveysData, startDate, endDate)
      : "";
    text += selectedCategories.includes(ExportCategories.Performance)
      ? performanceText(
          slotDays,
          performanceData,
          fitnessTestData,
          sleepTestData,
          stressTestData,
          energyTestData,
          useMetric,
          startDate,
          endDate
        )
      : "";
    copyToClipboard(text);
    trackUserReportCopied({
      fromDate: startDate.toISOString(),
      informationIncluded: getInformationIncludedArray(selectedCategories)
    });
    displaySuccessToast({ message: t("exportPreview.copySuccess") });
  };

  const copyToClipboard = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  // when startDate or EndDate changes we need to fetch mission scores and surveydata again
  useEffect(() => {
    getSpecificMissions();
    fetchSurveyResultData();
  }, [startDate, endDate]);

  const resetState = () => {
    setStartDate(DateTime.now().minus({ month: 1 }).toJSDate());
    setEndDate(undefined);
    setSelectedCategories(DEFAULT_CATEGORIES);
  };

  return (
    <ExportContext.Provider
      value={{ startDate, endDate, isLoading, setIsLoading }}
    >
      <Modal
        title={t("exportPreview.title", "Export PDF")}
        className={`${styles.modal}`}
        onClose={close}
      >
        <SentryErrorBoundary
          resetState={resetState}
          transactionName="ExportPreview"
        >
          <div className={styles.container}>
            {selectedCategories.length > 0 ? (
              <div className={styles.content}>
                <div className={`${styles.report} ${styles.preview}`}>
                  <PrintUserReport
                    preview={true}
                    userDetails={detail}
                    language={language ?? ""}
                    coach={coach}
                    program={program}
                    timeZoneOffset={timeZoneOffset ?? 0}
                    useMessages={selectedCategories.includes(
                      ExportCategories.MessagesSMS
                    )}
                    usePRO={selectedCategories.includes(ExportCategories.PRO)}
                    usePerformance={selectedCategories.includes(
                      ExportCategories.Performance
                    )}
                    useNotes={selectedCategories.includes(
                      ExportCategories.Notes
                    )}
                    communicationPreferences={communicationPreferences}
                    useCommunicationPreferences={selectedCategories.includes(
                      ExportCategories.CommunicationPreferences
                    )}
                    useFoodJournal={selectedCategories.includes(
                      ExportCategories.FoodJournal
                    )}
                    useInformation={selectedCategories.includes(
                      ExportCategories.Information
                    )}
                    leftDate={leftDate}
                    messages={detail?.messageThreads ?? []}
                    fitnessTestData={fitnessTestData}
                    sleepTestData={sleepTestData}
                    energyTestData={energyTestData}
                    stressTestData={stressTestData}
                    performanceData={performanceData}
                    useMetric={useMetric}
                    surveysData={surveysData}
                    slotDays={slotDays}
                    getMessageSenderName={getMessageSenderName}
                    smsMessages={smsMessages}
                  />
                </div>
                <div className={`${styles.report}`}>
                  <PrintUserReport
                    preview={false}
                    userDetails={detail}
                    language={language ?? ""}
                    coach={coach}
                    communicationPreferences={communicationPreferences}
                    program={program}
                    timeZoneOffset={timeZoneOffset}
                    useMessages={selectedCategories.includes(
                      ExportCategories.MessagesSMS
                    )}
                    usePRO={selectedCategories.includes(ExportCategories.PRO)}
                    usePerformance={selectedCategories.includes(
                      ExportCategories.Performance
                    )}
                    useNotes={selectedCategories.includes(
                      ExportCategories.Notes
                    )}
                    useFoodJournal={selectedCategories.includes(
                      ExportCategories.FoodJournal
                    )}
                    useInformation={selectedCategories.includes(
                      ExportCategories.Information
                    )}
                    useCommunicationPreferences={selectedCategories.includes(
                      ExportCategories.CommunicationPreferences
                    )}
                    ref={printUserReportRef}
                    leftDate={leftDate}
                    messages={detail?.messageThreads ?? []}
                    fitnessTestData={fitnessTestData}
                    sleepTestData={sleepTestData}
                    energyTestData={energyTestData}
                    stressTestData={stressTestData}
                    performanceData={performanceData}
                    useMetric={useMetric}
                    surveysData={surveysData}
                    slotDays={slotDays}
                    getMessageSenderName={getMessageSenderName}
                    smsMessages={smsMessages}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.content}>
                <div className={styles.noData}>
                  <figure>
                    <img src={NoDataSidekick} className="no-users-image" />
                    <figcaption>
                      {t(
                        "exportPreview.noData",
                        "There is no data to export with the selected filters"
                      )}
                    </figcaption>
                  </figure>
                </div>
              </div>
            )}

            <div className={styles.filters}>
              <span>{t("exportPreview.period", "Period")}</span>
              <div className={styles.datePicker}>
                <CustomDatePicker
                  placeholderText={t("general.from", "From")}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  locale={locale}
                  maxDate={endDate}
                />
                <CustomDatePicker
                  placeholderText={t("general.to", "To")}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  locale={locale}
                  minDate={startDate}
                />
              </div>
              <span>{t("exportPreview.includes")}</span>
              <CheckboxInput
                label={
                  DEFAULT_CATEGORIES.length === selectedCategories.length
                    ? t("general.clearAll")
                    : t("general.selectAll")
                }
                checked={
                  DEFAULT_CATEGORIES.length === selectedCategories.length
                }
                onChange={(checked) =>
                  !checked
                    ? setSelectedCategories([])
                    : setSelectedCategories(Object.values(ExportCategories))
                }
                className={`${styles.filterCheckbox} ${styles.topFilter}`}
              />
              {DEFAULT_CATEGORIES.map((category, index) => (
                <CheckboxInput
                  key={`export-category-${index}`}
                  label={category}
                  checked={selectedCategories.includes(category)}
                  onChange={() => toggleCategory(category)}
                  className={styles.filterCheckbox}
                />
              ))}
            </div>
          </div>
          <div className={styles.actions}>
            <button
              type="button"
              onClick={handleCopyToClipboard}
              className="btn-secondary btn-sm"
              disabled={selectedCategories.length === 0}
            >
              <img src={CopyIcon} alt="copy"></img>
              {t("exportPreview.copy", "Copy")}
            </button>
            <ReactToPrint
              trigger={linkToPrint}
              content={() => printUserReportRef.current ?? null}
              documentTitle={`UserReport_${detail.id}_${DateTime.now().toFormat(
                "MM.dd.yyyy"
              )}`}
              onAfterPrint={() => {
                trackExport({
                  startDate: DateTime.fromJSDate(startDate),
                  endDate: endDate ? DateTime.fromJSDate(endDate) : undefined,
                  informationIncluded: getInformationIncludedArray(
                    selectedCategories
                  )
                });
                close();
              }}
            />
          </div>
        </SentryErrorBoundary>
      </Modal>
    </ExportContext.Provider>
  );
};

export default ExportPreview;
