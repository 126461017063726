import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import useLoggedIn from "../useLoggedIn";

import { SegmentationKey } from "~/constants/segmentationKeys";

type Props = {
  featureName: SegmentationKey;
  patientId?: string;
};

export const useFeatureToggle = ({ featureName, patientId }: Props) => {
  const path = `/coach/features/${featureName}/enabled?${
    patientId ? "userId=" + patientId : ""
  }`;

  const isLoggedIn = useLoggedIn();

  const { data, isLoading, invalidate } = useApi<{ enabled: boolean }>({
    queryKey: QueryKeyFactory.segmentation(featureName, patientId),
    path,
    refetchOnWindowFocus: false,
    shouldRetry: false,
    disabled: !isLoggedIn
  });

  return {
    isEnabled: data?.enabled,
    isLoading,
    invalidate
  };
};
