import {
  FloatingPortal,
  useFloating,
  arrow,
  offset,
  flip,
  shift,
  useHover,
  useDismiss,
  useInteractions,
  FloatingArrow,
  autoUpdate
} from "@floating-ui/react";
import classNames from "classnames/bind";
import { useEffect, useRef, useState } from "react";

import styles from "./SymptomHighlight.module.scss";
import { digaMenoIconResolver } from "./SymptomHighlightHelpers";
import SymptomHighlightTooltip from "./SymptomHighlightTooltip";

import { SymptomSeverity, SymptomTrend } from "~/constants/symptoms";
import { SymptomLogDetails } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

type SymptomHighlightProps = {
  id: string;
  severity: SymptomSeverity;
  trend: SymptomTrend;
  hidden: boolean;
  logs?: SymptomLogDetails[];
  weekOld?: boolean;
};

const SymptomHighlight = ({
  id,
  severity,
  hidden,
  logs,
  trend,
  weekOld = false
}: SymptomHighlightProps) => {
  const [IconComponent, setIconComponent] = useState<React.ReactNode | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isModalVisible,
    onOpenChange: setIsModalVisible,
    placement: "top",
    middleware: [
      offset(10),
      flip({
        padding: {
          top: 72
        }
      }),
      shift({ padding: 32 }),

      arrow({
        element: arrowRef
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const hover = useHover(context, {
    enabled: Boolean(logs?.length)
  });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss
  ]);

  useEffect(() => {
    const resolveIcon = async () => {
      let severityToUse: SymptomSeverity = severity;
      if (weekOld) {
        severityToUse = SymptomSeverity.NotTracked;
      }
      const resolvedIcon = await digaMenoIconResolver(id, severityToUse, trend);
      setIconComponent(resolvedIcon);
    };

    resolveIcon();

    return () => {
      setIconComponent(null);
    };
  }, [severity, trend]);

  return (
    <div className={styles.wrapper}>
      {!hidden ? (
        <div
          className={cx({
            [styles.overlay]: true,
            [styles.hidden]: !isModalVisible
          })}
        />
      ) : null}

      <div
        ref={refs.setReference}
        className={cx({
          [styles.container]: true,
          [styles.hidden]: hidden,
          [styles.highlighted]: isModalVisible
        })}
        {...getReferenceProps()}
      >
        <FloatingPortal>
          {isModalVisible && (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={cx({
                [styles.highlighted]: isModalVisible
              })}
            >
              <FloatingArrow ref={arrowRef} context={context} fill="white" />
              <SymptomHighlightTooltip id={id} logs={logs ?? []} />
            </div>
          )}
        </FloatingPortal>

        <div
          className={cx({
            title: true,
            red: trend === SymptomTrend.Severe && !weekOld,
            green: trend === SymptomTrend.Improvement && !weekOld,
            notTracked: severity === SymptomSeverity.NotTracked || weekOld
          })}
        >
          {id}
        </div>
        <div
          className={cx({
            symptom: true,
            red: trend === SymptomTrend.Severe && !weekOld,
            green: trend === SymptomTrend.Improvement && !weekOld,
            notTracked: severity === SymptomSeverity.NotTracked || weekOld
          })}
        >
          {IconComponent}
        </div>
        <div
          className={cx({
            severity: true,
            red: trend === SymptomTrend.Severe && !weekOld,
            green: trend === SymptomTrend.Improvement && !weekOld,
            notTracked: severity === SymptomSeverity.NotTracked || weekOld
          })}
        >
          {severity}
        </div>
      </div>
    </div>
  );
};

export default SymptomHighlight;
