import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { ExternalUserGroupOutput } from "~/typing/sidekickTypes";

interface Props {
  /**
   * The identifier of the file. If none is supplied, fetch the latest file.
   * @type {number | "latest"}
   */
  fileUploadId?: number | "latest";

  /**
   * Whether to filter out terminated groups. Should be set to true if choosing a new group to assign a user.
   * @type {boolean}
   */
  skipTerminatedClients?: boolean;

  /**
   * Whether the API call should be disabled.
   * @type {boolean}
   */
  disabled: boolean;
}

export default function useExternalUserGroups({
  fileUploadId = "latest",
  skipTerminatedClients = false,
  disabled
}: Props) {
  const path = `/coach/externalusergroups/${fileUploadId}`;

  const { data, isError, isLoading, invalidate } = useApi<{
    items: ExternalUserGroupOutput[];
  }>({
    path,
    queryKey: QueryKeyFactory.userGroups(skipTerminatedClients),
    disabled
  });

  const filteredGroups = (groups: ExternalUserGroupOutput[]) => {
    if (!skipTerminatedClients) return groups;

    return groups.filter((g) => {
      if (!g.clientTerminationDate) return true;

      const terminationDate = new Date(g.clientTerminationDate);
      return (
        isNaN(terminationDate.getTime()) ||
        terminationDate.getTime() > Date.now()
      );
    });
  };

  return {
    groups: filteredGroups(data?.items ?? []),
    isError,
    isLoading,
    invalidate
  };
}