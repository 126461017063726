import { useEffect, useState } from "react";

import { Survey, SurveyQuestion } from "~/typing/sidekickTypes";

export const useSurveyQuestions = ({ survey }: { survey?: Survey }) => {
  const [questions, setQuestions] = useState<SurveyQuestion[] | undefined>();

  useEffect(() => {
    const allQuestions = survey?.questions;

    allQuestions?.forEach((question, index) => {
      if (question.optionsOutput?.embedded) {
        for (let i = index; i >= 0; i--) {
          const prevQuestion = allQuestions?.[i];
          prevQuestion?.answers?.forEach((answer) => {
            if (question.enabledByAnswerIds?.includes(answer.id)) {
              answer.embeddedQuestion = question;
              i = -1;
            }
          });
        }
      }
    });

    const questionsToDisplay = allQuestions?.filter(
      (question) => !question.title.includes("or calling 911")
    );

    setQuestions(questionsToDisplay);
  }, [survey]);

  return { questions };
};
