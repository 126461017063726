import styles from "./DiscreteScale.module.scss";

import cellStyleForValue from "~/helpers/cellStyleForValue";
import { SurveyResultAnswerSeverity } from "~/typing/sidekickTypes";

type DiscreteScaleProps = {
  uniqueValues: number[];
  isCurrentRating: (value: number) => boolean;
  answerSeverities?: SurveyResultAnswerSeverity[];
};

export default function DiscreteScale({
  uniqueValues,
  isCurrentRating,
  answerSeverities
}: DiscreteScaleProps) {
  const useAnswerSeverity = !!answerSeverities;

  return (
    <div className={styles.scale}>
      {uniqueValues.map((value) => {
        const isSelected = isCurrentRating(value);
        const style = cellStyleForValue(value, uniqueValues, answerSeverities);
        return (
          <div
            key={value}
            className={`${styles.value} ${isSelected ? styles.selected : ""}`}
            style={style}
          >
            {isSelected ? (
              <>
                <div className={styles.border} style={style}></div>
                <div className={styles.text} style={style}>
                  {useAnswerSeverity ? null : value}
                </div>
              </>
            ) : (
              <>{useAnswerSeverity ? null : value}</>
            )}
          </div>
        );
      })}
    </div>
  );
}
