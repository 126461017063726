import { isLocalhost } from "./helpers/browser/browserHelpers";

const config = {
  // URLs
  carePortalGraphQlUrl: import.meta.env.VITE_CAREPORTAL_GRAPHQL_URL,
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  authBaseUrl: import.meta.env.VITE_AUTH_API_BASE_URL,
  legalBaseUrl: import.meta.env.VITE_LEGAL_BASE_URL,
  anthemHRSDeepLinkBaseUrl: import.meta.env.VITE_ANTHEM_HRS_DEEP_LINK_BASE_URL,
  anthemNurselineDeepLinkBaseUrl: import.meta.env
    .VITE_ANTHEM_NURSELINE_DEEP_LINK_BASE_URL,
  anthemCareCompassDeepLinkBaseUrl: import.meta.env
    .VITE_ANTHEM_CARE_COMPASS_DEEP_LINK_BASE_URL,
  anthemHipDeepLinkBaseUrl: import.meta.env.VITE_ANTHEM_HIP_DEEP_LINK_BASE_URL,
  assistanctCoachUrl: import.meta.env.VITE_CHAT_ASSISTANT_URL,

  // Booleans
  isAnthem: import.meta.env.VITE_IS_ANTHEM === "true",
  useSms: import.meta.env.VITE_USE_SMS === "true" || isLocalhost,
  isProduction:
    import.meta.env.VITE_SK_PLATFORM === "sidekick-prod" ||
    import.meta.env.VITE_SK_PLATFORM === "anthem-prod",

  // Keys and IDs
  amplitudeKey: import.meta.env.VITE_AMPLITUDE_KEY,
  zendeskKey: import.meta.env.VITE_ZENDESK_KEY,
  privacyVersion: Number(import.meta.env.VITE_PRIVACY_POLICY_VERSION),
  termsVersion: Number(import.meta.env.VITE_TERMS_AND_CONDITIONS_VERSION),
  appId: Number(import.meta.env.VITE_SK_APP_ID),
  platform: import.meta.env.VITE_SK_PLATFORM,
  sentryKey:
    "https://d394547dcaf24e1f966d59cb48eee8b7@o491702.ingest.sentry.io/5557684",

  // Behaviour
  showProviderSettings: import.meta.env.VITE_SHOW_PROVIDER_SETTINGS === "true",
  allowUserConfigurableUnits:
    import.meta.env.VITE_ALLOW_USER_CONFIGURABLE_UNITS === "true",
  allowLanguageSelection:
    import.meta.env.VITE_ALLOW_LANGUAGE_SELECTION === "true",
  enableZendesk: import.meta.env.VITE_ENABLE_ZENDESK === "true" && !isLocalhost,
  allowInviteProviders: import.meta.env.VITE_ALLOW_INVITE_PROVIDERS === "true",
  showMessageSuggestions:
    import.meta.env.VITE_SHOW_MESSAGE_SUGGESTIONS === "true",
  sendMessagesAsSystemBot: import.meta.env.VITE_SEND_MESSAGES_AS_BOT === "true"
};

export default config;
