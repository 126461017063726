import MessageSuggestion from "~/components/messageSuggestion/MessageSuggestion";
import {
  MessageSuggestionDto,
  ProgramProgressionMetadata
} from "~/typing/graphql/types";

type ProgramProgressionProps = {
  userId: string;
  metadata: ProgramProgressionMetadata;
};

const ProgramProgression = ({ userId, metadata }: ProgramProgressionProps) => {
  const validMessages: MessageSuggestionDto[] = metadata.messageSuggestion.filter(
    (message): message is MessageSuggestionDto =>
      message !== null && message !== undefined
  );

  return (
    <MessageSuggestion
      messages={validMessages}
      userId={userId}
      simulateMessageGeneration
      graphQLMessageReport
    />
  );
};

export default ProgramProgression;
