import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MessageSuggestionReport.module.scss";

import CheckboxInput from "../checkboxInput/CheckboxInput";

import { ReportMessageProps } from "~/hooks/useApi/useCoachSuggestedMessages";
import { MessageSuggestionDto } from "~/typing/graphql/types";

const DEFAULT_REPORT_REASONS_STRINGS = [
  "The sentence is poorly written",
  "The data within the sentence looks strange",
  "The sentence should not appear for this user"
];

const DEFAULT_REPORT_REASONS = new Map([
  [DEFAULT_REPORT_REASONS_STRINGS[0], false],
  [DEFAULT_REPORT_REASONS_STRINGS[1], false],
  [DEFAULT_REPORT_REASONS_STRINGS[2], false]
]);

type MessageSuggestionReportProps = {
  message: MessageSuggestionDto;
  userId: string;
  onReportMessageChange: (message: ReportMessageProps) => void;
  successfullyReported?: boolean;
};

export const MessageSuggestionReport = ({
  message,
  userId,
  onReportMessageChange,
  successfullyReported
}: MessageSuggestionReportProps) => {
  const { t } = useTranslation();

  const [reportMessage, setReportMessage] = useState<ReportMessageProps>({
    suggestionId: message.suggestionId,
    user_id: parseInt(userId),
    message: message.message,
    issues: [],
    note: ""
  });

  const [reportReasons, setReportReasons] = useState<Map<string, boolean>>(
    new Map(DEFAULT_REPORT_REASONS)
  );

  useEffect(() => {
    const issues = Array.from(reportReasons.entries())
      .filter(([, value]) => value)
      .map(([key]) => key);

    setReportMessage((prev) => ({ ...prev, issues }));
  }, [reportReasons]);

  useEffect(() => onReportMessageChange(reportMessage), [reportMessage]);

  useEffect(() => {
    setReportReasons(new Map(DEFAULT_REPORT_REASONS));
    setReportMessage({
      suggestionId: message.suggestionId,
      user_id: parseInt(userId),
      message: message.message,
      issues: [],
      note: ""
    });
  }, [message]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.message}>
          <p>{t("nextStep.reportMessage.message")}</p>
          <textarea
            className={styles.messageTextarea}
            disabled
            rows={5}
            value={message.message}
          />
        </div>
        {!successfullyReported ? (
          <>
            <div className={styles.checklist}>
              <p>{t("nextStep.reportMessage.reportQustion")}</p>
              <ul>
                {Array.from(reportReasons).map((value, key) => (
                  <li key={key}>
                    <CheckboxInput
                      checked={value[1]}
                      onChange={(checked) =>
                        setReportReasons(
                          (prev) => new Map(prev.set(value[0], checked))
                        )
                      }
                      label={value[0]}
                      className={styles.reportCheckbox}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.noteWrapper}>
              <p>{t("nextStep.reportMessage.other")}</p>
              <textarea
                className={styles.reportTextarea}
                placeholder={t("nextStep.reportMessage.writeFeedback")}
                onChange={(e) =>
                  setReportMessage((prev) => ({
                    ...prev,
                    note: e.target.value
                  }))
                }
                value={reportMessage.note}
                autoFocus
              />
            </div>
          </>
        ) : (
          <div className={styles.reportSuccess}>
            {t("nextStep.reportMessage.reportSuccess")}
          </div>
        )}
      </div>
    </>
  );
};
