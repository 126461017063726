import colors from "~/styles/colors";

type CheckProps = {
  color?: string;
};

const Check = ({ color = colors.black }: CheckProps) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8047 4.02851C14.0651 4.28886 14.0651 4.71097 13.8047 4.97132L6.4714 12.3047C6.21106 12.565 5.78894 12.565 5.5286 12.3047L2.19526 8.97132C1.93491 8.71097 1.93491 8.28886 2.19526 8.02851C2.45561 7.76816 2.87772 7.76816 3.13807 8.02851L6 10.8904L12.8619 4.02851C13.1223 3.76816 13.5444 3.76816 13.8047 4.02851Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Check;
