import { FloatingArrow, FloatingPortal, Placement } from "@floating-ui/react";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./FloatingTooltip.module.scss";

import useCustomFloating from "~/hooks/useCustomFloating";

const cx = classNames.bind(styles);

type FloatingTooltipProps = {
  children: ReactNode;
  tooltipContent: ReactNode;
  placement?: Placement;
  useMaxWidth?: boolean;
  showForDebugging?: boolean;
  triggerOnClick?: boolean;
  tooltipClassName?: string;
  toggleTooltip?: boolean;
};

const FloatingTooltip = ({
  children,
  tooltipContent,
  placement = "top",
  useMaxWidth = true,
  showForDebugging,
  triggerOnClick,
  tooltipClassName,
  toggleTooltip
}: FloatingTooltipProps) => {
  const {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
    context,
    isOpen,
    setIsOpen
  } = useCustomFloating({ placement, triggerOnClick });

  const shouldShowTooltip = showForDebugging ? true : isOpen;

  useEffect(() => {
    if (toggleTooltip !== undefined) {
      setIsOpen(toggleTooltip);
    }
  }, [shouldShowTooltip]);

  return (
    <>
      <div
        className={styles.tooltipAnchor}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </div>

      <FloatingPortal>
        {shouldShowTooltip && (
          <div
            className={cx(styles.tooltip, {
              [styles.maxWidth]: useMaxWidth,
              [tooltipClassName ?? ""]: !!tooltipClassName
            })}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {tooltipContent}
            <FloatingArrow ref={arrowRef} context={context} fill="white" />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default FloatingTooltip;
