import { isMedicareProgram } from "../program/programHelpers";

import config from "~/config";
import { EnrichedAuthenticatedUser, Program } from "~/typing/sidekickTypes";

export function isLeadCoach(user: EnrichedAuthenticatedUser | undefined) {
  if (!user) return false;
  return Number(user.privileges) >= 15;
}

export function isMedicareLeadCoach({
  user,
  programs
}: {
  user?: EnrichedAuthenticatedUser;
  programs: Program[];
}) {
  if (!config.isAnthem || !user) {
    return false;
  }
  const isMedicareCoach = !!(programs || []).find(isMedicareProgram);
  return isMedicareCoach && isLeadCoach(user);
}
