import { useMutation } from "@tanstack/react-query";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";

import { reportMessage } from "~/api/graphql/nextStep";
import { MutationReportMessageArgs } from "~/typing/graphql/types";

export const useReportMessage = () => {
  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: QueryKeyFactory.users.reportMessage(),
    mutationFn: (args: MutationReportMessageArgs) => reportMessage(args),
    retry: false
  });

  return {
    reportMessage: mutateAsync,
    isPending,
    isError,
    isSuccess
  };
};
