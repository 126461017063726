import classNames from "classnames/bind";

import styles from "./NextStepUserMetrics.module.scss";

import ArrowIcon from "~/assets/arrow-right-thick.svg";
import ActiveDaysIcon from "~/assets/metrics/active-days.svg";
import EnergyIcon from "~/assets/metrics/energy.svg";
import ScoreCountIcon from "~/assets/metrics/score-count.svg";
import SleepIcon from "~/assets/metrics/sleep.svg";
import StepCountIcon from "~/assets/metrics/step-count.svg";
import StressIcon from "~/assets/metrics/stress.svg";
import TaskComplianceIcon from "~/assets/metrics/task-compliance.svg";
import ActivityMinutesIcon from "~/assets/metrics/watch-clock.svg";
import InfoTooltip from "~/components/infoTooltip/InfoTooltip";
import config from "~/config";
import round from "~/helpers/number/round";
import { MetricType, UserMetricData } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);

const Metric = ({
  metric: { title, metric, value, change, info }
}: {
  metric: UserMetricData;
}) => {
  const getValue = ({
    value,
    metric
  }: {
    value: number | string | undefined;
    metric?: string;
  }) => {
    if (value === undefined) {
      return "-";
    }

    const finalValue =
      typeof value === "number"
        ? round(value, 2)?.toLocaleString(config.isAnthem ? "en-US" : "en-GB")
        : value;

    return `${finalValue} ${metric ?? ""}`;
  };

  const getMetricsIcon = (title: MetricType) => {
    switch (title) {
      case MetricType.SleepQuality:
        return SleepIcon;
      case MetricType.EnergyLevels:
        return EnergyIcon;
      case MetricType.StressLevels:
        return StressIcon;
      case MetricType.ActiveDays:
        return ActiveDaysIcon;
      case MetricType.PhysicalActivityMinutes:
        return ActivityMinutesIcon;
      case MetricType.MissionsCompleted:
        return ScoreCountIcon;
      case MetricType.StepCount:
        return StepCountIcon;
      case MetricType.TaskCompliance:
        return TaskComplianceIcon;
      default:
        return undefined;
    }
  };

  const metricIcon = getMetricsIcon(title);

  const ChangeIndicator = () => {
    if (!change) return null;

    const classes = cx({
      change: true,
      positive: !change.negative && change.value !== 0,
      negative: change.negative !== undefined && change.negative,
      up: change.value > 0,
      down: change.value < 0,
      zero: change.value === 0
    });

    return (
      <div className={classes}>
        <img className={cx({ down: change.value < 0 })} src={ArrowIcon} />
        {`${round(Math.abs(change.value), 0)}%`}
      </div>
    );
  };

  return (
    <div className={cx({ metric: true })}>
      <div className={styles.metricInfo}>
        {metricIcon && (
          <div className={styles.icon}>
            <img src={metricIcon} />
          </div>
        )}
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <p>{title}</p> {info && <InfoTooltip content={info} />}
          </div>
          <p className={styles.value}>{`${getValue({ value, metric })}`}</p>
        </div>
      </div>
      <ChangeIndicator />
    </div>
  );
};

type NextStepUserMetricsProps = {
  metrics: UserMetricData[];
};

const NextStepUserMetrics = ({ metrics }: NextStepUserMetricsProps) => {
  return (
    <section className={styles.metricsWrapper}>
      <div className={styles.grid}>
        {metrics.map((metric, index) => (
          <Metric key={`metric-${metric.title}-${index}`} metric={metric} />
        ))}
      </div>
    </section>
  );
};

export default NextStepUserMetrics;
