import { DateTime } from "luxon";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./Message.module.scss";
import { MessageListContext } from "./MessageList.context";
import { MessageWindowContext } from "./messageWindowContext";

import DoubleCheckMark from "~/assets/svgComponents/DoubleCheckMark";
import Avatar from "~/components/avatar/Avatar";
import Notification from "~/components/notification/Notification";
import Options from "~/components/options/Options";
import { TimeInMs } from "~/constants/measurements";
import { SMSStatus, TextMessageType } from "~/constants/textMessaging";
import humanizeTimeDiff from "~/helpers/date/humanizeTimeDiff";
import getMessageHtml from "~/helpers/getMessageHtml";
import { copyToClipboard } from "~/helpers/util/util";
import { useReadSmsMessages } from "~/hooks/useApi/messages/useReadSms";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { ProgramURLParams } from "~/typing/carePortalTypes";
import { SMSMessage, UserDetailsUser } from "~/typing/sidekickTypes";

type MessageProps = {
  message: SMSMessage;
  user: {
    user: UserDetailsUser;
    externalUser?: { externalUserTypeId: string; id: string };
  };
  isHighestRead?: boolean;
};

const SMSChatMessage = ({ message, user, isHighestRead }: MessageProps) => {
  const { t } = useTranslation();
  const { newNextStep } = useContext(TaskPageContext);
  const { searchQuery } = useContext(MessageListContext);
  const { usersForAvatars, allCareManagersShown } = useContext(
    MessageWindowContext
  );

  const messageRef = useRef<HTMLDivElement>(null);

  const { program_id, locale } = useParams<ProgramURLParams>();

  const [showOptions, setShowOptions] = useState(false);

  const showOptionsIfPossible = () => {
    if (message.type === TextMessageType.UserResponse) {
      setShowOptions(true);
    }
  };

  const { readSmsMessage } = useReadSmsMessages({
    programId: program_id ?? "",
    locale: locale ?? "",
    externalUser: user.externalUser
  });

  const avatarUser =
    usersForAvatars[
      message.type === TextMessageType.UserResponse ? 0 : message.senderUserId
    ];

  const createdDate = DateTime.fromISO(message.createdDate);
  const seenDate = message.seenDate
    ? DateTime.fromISO(message.seenDate)
    : undefined;
  const isRead = seenDate && seenDate.diff(createdDate).toMillis() > 0;

  const isCoachReceiver =
    message.senderUserId === user?.user?.id ||
    message.senderUserId === user?.user?.userId;
  const showReadReceipt = isRead && !isCoachReceiver;

  const smsError =
    (message.smsStatus === SMSStatus.Unknown &&
      message.type !== TextMessageType.UserResponse) ||
    message.smsStatus === SMSStatus.Failed ||
    message.smsStatus === SMSStatus.Undelivered;

  let classes = `${styles.message} ${styles.sms} ${
    newNextStep ? styles.newNextStep : ""
  }`;
  if (message.type !== TextMessageType.UserResponse) {
    // Message sent from coach to user
    classes += ` ${styles.sent}`;
  } else if (isCoachReceiver) {
    // Message sent from user to coaches
    classes += ` ${styles.received}`;
  } else {
    // Message sent from other coach to user
    classes += ` ${styles.sent} ${styles.otherCoach}`;
  }

  // it is possible we get negative time here, if server is on different time than client
  const diff = Math.max(Date.now() - createdDate.toMillis(), 0);
  const humanTime = humanizeTimeDiff(diff);

  const printTime = createdDate.toLocaleString({
    month: "numeric",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });

  const tooltipTime = createdDate.toLocaleString({
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
  const seenTime = seenDate?.toLocaleString({
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });

  const messageIsUnread =
    !isRead && message.type === TextMessageType.UserResponse;

  return (
    <div
      className={classes}
      onMouseEnter={() => showOptionsIfPossible()}
      onMouseLeave={() => setShowOptions(false)}
    >
      {!newNextStep && (
        <Avatar size="sm" user={avatarUser} title={avatarUser?.fullName} />
      )}
      <div className={styles.messageWrapper} ref={messageRef}>
        {messageIsUnread && (
          <Notification
            size="xs"
            count={1}
            className={styles.smsNotification}
          />
        )}

        <div className={styles.textWrapper}>
          {smsError && (
            <div className={styles.error} title={t("messages.errorSMS")}>
              !
            </div>
          )}
          {allCareManagersShown && !isCoachReceiver && (
            <div className={styles.metadata}>
              <span className={styles.coachName}>
                {usersForAvatars[message.senderUserId]?.displayName}
              </span>
              {" Care manager"}
            </div>
          )}
          <div className={`${styles.text} `}>
            {getMessageHtml(message.message, searchQuery)}
          </div>
        </div>

        <div className={styles.metadata}>
          <span className={styles.datestamp} title={tooltipTime}>
            {diff > TimeInMs.Day ? printTime : humanTime}
          </span>
          <span
            title={`${t("general.read")} ${seenTime}`}
            className={styles.deliveryStatus}
          >
            {showReadReceipt && <DoubleCheckMark />}
            {/* We show the read checkmark for all read messages, but only the text for the latest one */}
            {showReadReceipt && isHighestRead && (
              <span title={`${t("general.read")} ${seenTime}`}>
                {t("general.read")}
              </span>
            )}
          </span>
        </div>
      </div>
      {showOptions && (
        <Options
          options={[
            {
              title: t("messages.markAsRead"),
              func: () => readSmsMessage({ message }),
              hidden: !program_id && !locale
            },
            {
              title: t("messages.copyText"),
              func: () => copyToClipboard(message.message)
            }
          ]}
        />
      )}
    </div>
  );
};

export default SMSChatMessage;
