import classNames from "classnames";
import { t } from "i18next";
import { DateTime } from "luxon";

import styles from "./PROQuestionRow.module.scss";

import AnswerBubble from "../../../../../../../components/answerBubble/AnswerBubble";
import { SurveyResultToDisplay } from "../../hooks/useSurveyResultsToDisplay";
import { useSurveyResultTranslations } from "../../hooks/useSurveyResultTranslations";
import { getQuestionWithEmbeddedAnswers } from "../../PROTemplate.helpers";
import PROListAnswer from "../proListAnswer/PROListAnswer";
import PROTemplateImage from "../proTemplateImage/PROTemplateImage";

import DisableIcon from "~/assets/calendar-disable.svg";
import surveyQuestionDisplayTypes from "~/constants/surveyQuestionDisplayTypes";
import kgToLbs from "~/helpers/units/kgToLbs";
import useUseMetric from "~/hooks/useUseMetric";
import {
  Survey,
  SurveyQuestion,
  SurveyResultAnswer,
  SurveyResultQuestion
} from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

type PROQuestionRowProps = {
  question: SurveyQuestion;
  survey?: Survey;
  surveyResultsToDisplay: SurveyResultToDisplay[];
  hasRatings: boolean;
  useTextBased: boolean;
  uniqueValues: number[];
  setAnswerInModal: (props: { attachmentUrl?: string; date?: Date }) => void;
};

const PROQuestionRow = ({
  surveyResultsToDisplay,
  question,
  survey,
  hasRatings,
  useTextBased,
  uniqueValues,
  setAnswerInModal
}: PROQuestionRowProps) => {
  const useMetric = useUseMetric();

  const { translationLoading, translations } = useSurveyResultTranslations({
    surveyResultsToDisplay
  });

  const getUniqueValuesForQuestion = (question: SurveyQuestion) => {
    const values: number[] = [];

    if (survey?.answerSeverities) {
      survey.answerSeverities.forEach((severity) => {
        values.push(severity.value);
      });
    } else {
      question.answers?.forEach((answer) => {
        if (!values.includes(answer.ratingValue)) {
          values.push(answer.ratingValue);
        }
      });
    }
    return values.sort((a, b) => a - b);
  };

  const getAnswerSeverity = (id: string) => {
    return survey?.answerSeverities?.find((severity) => severity.id === id);
  };

  /**
   * Creates the answers using the correct format based on whether to use metric or not.
   * @param question The question whose answer should be used.
   * @param useMetric Whether to use metric or not.
   * @returns The weight and the units based on the correct format. Kg or Lbs
   */
  const weightQuestionElement = (
    question: SurveyResultQuestion,
    useMetric: boolean
  ) => {
    const weight = question.answers?.[0]?.answer;
    const displayWeight = useMetric ? weight : kgToLbs(parseInt(weight));
    const units = useMetric ? "kg" : "lbs";
    return `${displayWeight} ${units}`;
  };

  const multiSelectionElement = (
    answerObj: SurveyResultAnswer,
    questionIndex?: number,
    proIndex?: number
  ) => {
    const { title, answer, ratingValue, surveyAnswerSeverityId } = answerObj;
    const value = surveyAnswerSeverityId
      ? getAnswerSeverity(surveyAnswerSeverityId)?.value
      : ratingValue;

    const answerText = title || answer;
    const defaultAnswer = <div className={styles.answer}>{answerText}</div>;

    return (
      <li key={answer}>
        {questionIndex !== undefined && proIndex !== undefined ? (
          <PROListAnswer
            proIndex={proIndex}
            questionIndex={questionIndex}
            defaultAnswer={defaultAnswer}
            translations={translations}
            translationsLoading={translationLoading}
          />
        ) : (
          defaultAnswer
        )}
        {!surveyAnswerSeverityId && uniqueValues && uniqueValues.length > 1 && (
          <div className={styles.rating}>{value}</div>
        )}
      </li>
    );
  };

  const singleSelectionElement = (
    question: SurveyResultQuestion,
    questionIndex?: number,
    proIndex?: number
  ) => {
    if (!question?.answers) {
      return null;
    }

    const { title, answer } = question.answers?.[0];
    const unit = question.unit === undefined ? "" : question.unit;
    const defaultAnswer = <span>{title || answer + " " + unit}</span>;

    if (question.answers)
      return (
        <li>
          {translations !== undefined &&
          questionIndex !== undefined &&
          proIndex !== undefined &&
          translations.length > proIndex ? (
            <PROListAnswer
              proIndex={proIndex}
              questionIndex={questionIndex}
              defaultAnswer={defaultAnswer}
              translations={translations}
              translationsLoading={translationLoading}
            />
          ) : (
            defaultAnswer
          )}
        </li>
      );
  };

  /**
   * Returns a PROTemplateImage element for the question supplied.
   * @param question The question whos image will be returned.
   * @returns The element for the image.
   */
  const imageElement = (
    question: SurveyResultQuestion,
    date: Date | undefined
  ) => {
    return (
      <PROTemplateImage
        answer={question.answers?.[0]}
        onImageClick={() =>
          setAnswerInModal({
            attachmentUrl: question.answers?.[0].attachmentUrl,
            date
          })
        }
      />
    );
  };

  /**
   * Determines what type of question should be displayed.
   * @param question The question to be displayed.
   * @param useSkipped Determines whether the question should be skipped but displayed.
   * @returns The element to displayed corresponding to the question type.
   */

  const answerDisplay = ({
    question,
    useSkipped,
    date,
    questionIndex,
    proIndex
  }: {
    question?: SurveyResultQuestion;
    useSkipped?: boolean;
    date?: Date;
    questionIndex?: number;
    proIndex?: number;
  }) => {
    if (!question) {
      if (useSkipped) {
        return t("pro.skipped", "Skipped");
      } else {
        return "";
      }
    }

    const displayType = question.displayType;
    const { weight, multiSelection, image } = surveyQuestionDisplayTypes;

    switch (displayType) {
      case weight:
        return weightQuestionElement(question, useMetric);
      case multiSelection:
        return question.answers?.map((answerObj) =>
          multiSelectionElement(answerObj, questionIndex, proIndex)
        );
      case image:
        return imageElement(question, date);
      default:
        return singleSelectionElement(question, questionIndex, proIndex);
    }
  };

  if (question.optionsOutput?.embedded) {
    return null;
  }

  return (
    <tr
      className={cx({
        [styles.textResults]: useTextBased,
        [styles.hasNoRatings]: survey && !hasRatings
      })}
    >
      <th>
        <div className={styles.questionTitle}>
          {question.title}
          {question.optionsOutput?.excludeFromResults ? (
            <span
              className={styles.questionDescriptor}
              title={t("pro.excludedFromResults")}
            >
              <img src={DisableIcon} />
            </span>
          ) : null}
        </div>
      </th>
      {surveyResultsToDisplay.length === 0 && (
        <td>
          <div className={styles.answerContainer}>
            {(hasRatings || !useTextBased) && (
              <AnswerBubble proExists={false} uniqueValues={uniqueValues} />
            )}
          </div>
        </td>
      )}
      {surveyResultsToDisplay.map((pro, index) => {
        const {
          question: answeredQuestion,
          index: questionIndex
        } = getQuestionWithEmbeddedAnswers(
          question,
          pro.questions ?? [],
          index,
          translations
        );
        if (!answeredQuestion)
          return <td key={`no-answer-${pro.id}-${index}`}></td>;
        //The answer cell. Can be an image.
        return (
          <td key={`answered-question-${pro.id}-${index}`}>
            <div className={styles.answerContainer}>
              {answeredQuestion?.displayType !==
                surveyQuestionDisplayTypes.image &&
                (hasRatings || !useTextBased) && (
                  <AnswerBubble
                    question={answeredQuestion}
                    proExists={!!pro}
                    uniqueValues={getUniqueValuesForQuestion(question)}
                    answerSeverities={survey?.answerSeverities}
                    answerDisplay={answerDisplay}
                    getAnswerSeverity={getAnswerSeverity}
                    questionIndex={questionIndex}
                    proIndex={index}
                  />
                )}
              {/* Text based answers that are not images*/}
              {answeredQuestion?.displayType !==
                surveyQuestionDisplayTypes.image &&
                useTextBased && (
                  <ul className={styles.answers}>
                    {answerDisplay({
                      question: answeredQuestion,
                      useSkipped: false,
                      questionIndex: questionIndex,
                      proIndex: index
                    })}
                  </ul>
                )}
              {/* Image answers */}
              {answeredQuestion?.displayType ===
                surveyQuestionDisplayTypes.image &&
                answerDisplay({
                  question: answeredQuestion,
                  useSkipped: false,
                  date: DateTime.fromISO(pro.date).toJSDate()
                })}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default PROQuestionRow;
